import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {logo,linkedIcon,instagramIcon} from "./assets";
import { styled } from "@material-ui/styles";
const MainWrapper = styled(`div`)({
  width: "50%",
  height: "auto",
  border: "3px solid black",
  position: "absolute",
  top: "70%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "@media (max-width :350px)":{
    width:"98%",
    height: "auto",
  border: "3px solid black",
  position: "absolute",
  top: "70%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  }
});
const Tag1 = styled(`p`)({
  "@media (max-width :350px)":{
    textAlign: "center",
  }
})
const Tag2 = styled(`p`)({
  "@media (max-width :350px)":{
    textAlign: "center",
    fontSize:"15px",
  }
})
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Emailnotifications2Controller, {
  Props,
  configJSON,
} from "./Emailnotifications2Controller";

export default class Emailnotifications2 extends Emailnotifications2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainWrapper>
        <div style={{ backgroundColor: "#594FF5", width: "100%", borderBottom: "3px solid black", boxSizing: "border-box", padding: "10 10 10 25" }}>
          <img src={logo} style={{
            height: 52,
          }} />
        </div>
        <div style={{ padding: "20px",display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center", }}>
          <p style={{fontSize:"25px",fontWeight:700,marginBottom:0}}>Hi,[first name]!</p>
          <p style={{textAlign:"center"}}>Thank you for creating an account on the<br/>
            London Filmed booking platform.</p>
          <Tag1>Your account manager is [first name surname].</Tag1>
            <Tag2>If there is anything you need with, you can<br/>
            get in touch with them at <span style={{color:"#594FF5"}}>[email address].</span></Tag2>
          <p style={{paddingBottom:"15px"}}>Or, feel free to</p>
          <div style={{ position: "relative" , marginBottom:"15px"}}>
            <div
              style={{
                position: "absolute",
                top: "5px",
                left: "5px",
                width: "100%",
                height: "100%",
                backgroundColor: "black",
                zIndex: 0,
              }}
            ></div>

            <div
              style={{
                width: "170px",
                border: "3px solid black",
                padding: "10px",
                textAlign: "center",
                backgroundColor: "#ffc34d",
                position: "relative",
                zIndex: 1,
                fontWeight:'bold',
              }}
            >
              Book a call
            </div>
          </div>

          <p style={{marginBottom:0}}>If you're ready to get started,</p>
          <p style={{marginTop:0, color:"#594FF5",textDecoration:"underline", textDecorationColor:"#594FF5"}}>create a quote for your next event.</p>
          <p style={{marginBottom:0}}>Thanks,</p>
          <p style={{marginTop:0}}>London Filmed</p>
        </div>
        <div style={{ borderTop:"3px solid black", padding:"20px" , backgroundColor:"#d9d9d9"}}>
          <div style={{display:"flex" , justifyContent:"center"}}>
            <img src={linkedIcon} style={{height:"20px" , border:"2px solid black"}}/>
            <img src={instagramIcon} style={{height:"20px", border:"2px solid black"}}/>
          </div>
        </div>
        </MainWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
