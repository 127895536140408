import React from "react";

//Customizable Area Start
import {
  Container,
  Box,
  Typography,
  Grid,
  TextField,
  Button
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Formik } from "formik";
import { styled } from "@material-ui/styles";
import Carousel from "react-material-ui-carousel";
import {
  imgLogo,
  imgBanner
} from "./assets";
import { View, StyleSheet, Text } from "react-native";
import * as Yup from "yup";
import NewPasswordController, {
  Props,
  configJSON
} from "./ForgotPasswordController.web";

//Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

export default class NewPassword extends NewPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container
          disableGutters
          maxWidth={false}
          style={{
            height: "100vh"
          }}
        >
          <View style={{backgroundColor:'#594FF5',height:76,borderBottomColor:'#000',borderBottomWidth:3,borderStyle:"solid"}}></View>
          <Grid
            container
            style={{
              height: "92%"
            }}
          >
            <Grid
              item
              sm={6}
              style={{
                backgroundColor: "#F6F0FF",
                borderRightColor:'#000',borderRightWidth:3,borderRightStyle:"solid",
                position: "relative",
                overflow: "hidden"
              }}
            >
                <View style={{height:'68%',alignItems:'center',justifyContent:'center'}}>
                  <img
                    src={imgBanner}
                    alt="carousel-right-tumbnail"
                    style={{width:'80%'}}
                  />
                </View>
              <View style={styles.staticBottomContent}>
                <img
                  src={imgLogo}
                  alt="mainImg"
                  style={{ height: "52px", marginBottom: "30px" }}
                />
                <Typography
                  style={{
                    color: "#000",
                    fontSize: "16px",
                    textAlign: "center",
                    lineHeight: "26px",
                    fontFamily: 'CircularStd',
                  }}
                >
                  {configJSON.supportText}
                </Typography>
              </View>
              
            </Grid>
            <Grid
              sm={6}
              item
              container
              alignItems="center"
              justifyContent="center"
              style={{
                height: "100%",
                flexDirection: "column"
              }}
            >
              <Box sx={{ width: "370px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px 0px"
                  }}
                >
                 
                  {/* -------------------- BODY ----------------------- */}
                  <View style={{ width: "100%" }}>
                    <Formik
                      initialValues={{  email: "" }}
                      validationSchema={Yup.object().shape(this.state.emailSchema)}
                      validateOnMount={true}
                      validateOnChange={true}
                      enableReinitialize
                      onSubmit={(values, actions) => {
                        this.goToEmailValidation(values);
                        actions.setSubmitting(false);
                      }}
                      data-testid="forgotPasswordEmailForm"
                    >
                      {({ setFieldValue, values, handleSubmit, touched, errors }) => (
                        <form onSubmit={handleSubmit} className="mt-5 mb-2">
                          <Grid container spacing={0}>
                          <Typography
                              variant="h4"
                              component="h2"
                              style={{
                                display: "flex",
                                fontFamily:'CircularStdBold',
                                fontWeight:500,
                                color: "#000000ff",
                                justifyContent: "center",
                                marginBottom: "30px",
                                alignItems: "center",
                                width:"100%",
                              }}
                            >
                              {configJSON.resetPwd}
                            </Typography>
                            {this.state.errorFailureMessage.length > 0 && (
                              <AlertBox data-testid="alerMessage">
                                {this.state.errorFailureMessage}
                              </AlertBox>
                            )}
                            {touched.email && errors.email  && (
                              <AlertBox data-testid="alerMessage">
                               {errors.email}
                              </AlertBox>
                            )}
                            <Grid container spacing={0}>
                              <Grid item xs={12}>
                                <Text style={styles.customLabel}>Email</Text>
                                <CustomFormWrapper>
                                  <CustomInputStyle
                                    data-testid="txtInputEmail"
                                    placeholder={"Your email"}
                                    fullWidth={true}
                                    name="email"
                                    value={values.email}
                                    onChange={(event) => {
                                      setFieldValue("email", event.target.value);
                                      this.resetErrorMessage();
                                    }
                                    }
                                  />
                                </CustomFormWrapper>
                               
                              </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 30 }}>
                              <CustomFormWrapper>
                                <CustomButtonOutline />
                                <SubmitButton type="submit" data-testid="changePasswordButton">
                                  {configJSON.resetPwd}
                                </SubmitButton>
                              </CustomFormWrapper>
                            </Grid>
                          </Grid>
                        </form>
                      )}
                    </Formik>
                  </View>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomFormOutline = styled("div")({
  border: "solid 3px #000",
  height: 33,
  position: "absolute",
  right: "-7px",
  bottom: 0,
  width: "100%",
  backgroundColor: "#594FF5",
  zIndex: 0
});

const CustomCarousel = styled(Carousel)({
  "@media(min-width:1200px)": {
    height: "90%"
  },
  ".CarouselItem": {
    height: "100%"
  }
});
const CustomFormWrapper = styled("div")({
  position: "relative",
  height: 46,
  display: "block"
});

const CustomInputStyle = styled(TextField)({
  "& .MuiInputBase-fullWidth": {
    margin: 0
  },
  "& .MuiInputBase-input": {
    backgroundColor: "#fff",
    height: "22px",
    position: "relative",
    zIndex: 1,
    borderColor: "#000",
    width: "100%",
    paddingLeft: "5px",
    paddingRight: "5px",
    borderStyle: "solid",
    borderWidth: 3,
    fontFamily: 'CircularStd',
  },
  "& inpur:before": {
    content: "none"
  }
});
const CustomButtonOutline = styled("div")({
  border: "solid 3px #000",
  height: 33,
  position: "absolute",
  right: "-5px",
  bottom: '2px',
  width: "98%",
  backgroundColor: "#594FF5",
  zIndex: 0,
});
const AlertBox = styled("div")({
  width:'100%',
  border:0,
  borderLeft:4,
  boxSizing:'border-box',
  borderTopLeftRadius:5,
  borderBottomLeftRadius:5,
  fontFamily: 'CircularStd',
  padding:20,
  backgroundColor: "#FEE2E2",
  borderLeftColor:"#FF5B74",
  borderStyle:"solid",
  marginBottom:30,
  color:"#FF5B74",
});

const SubmitButton = styled(Button)({
  backgroundColor: "#fff",
  color: "#000",
  border: "solid 3px #000",
  borderRadius: 0,
  textTransform: "none",

  fontSize: "14px",
  width: "100%",
  padding: "4px",
  fontFamily:'CircularStdBold', 
  "&:hover": {
    backgroundColor: "#BDB9FB",
  },
  "&:focus": {
    backgroundColor: "#DEDCFD",
  }
});


const styles = StyleSheet.create({
  customLabel: {
    fontSize: 16,
    color: "#334155",
    marginBottom: 5,
    display: "flex",
    fontFamily: 'CircularStdBold',
  },
  staticBottomContent: {
    maxWidth:'596px',
    padding: "0 20px",
    marginLeft: "auto",
    marginRight:"auto",
    alignItems: "center",
  }
});

// Customizable Area End
