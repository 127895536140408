import React from "react";
import {
  // Customizable Area Start
  Container,
  Box,
  Input,
  Typography,
  Grid,
  Button,
  TextareaAutosize ,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { styled } from "@material-ui/styles";
import { View, StyleSheet, Text} from "react-native";
import {
  imgBanner,
  imgLogo,
} from "./assets";
//@ts-ignore
import { Formik } from "formik";
import * as Yup from "yup";

const CustomFormWrapper = styled("div")({
  position: "relative",
  minHeight: 46,
  display: "block"
});
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const configJSON = require("./config");
import ContactusController, {
  Props,
} from "./ContactusController.web";

// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

  }

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container
          disableGutters
          maxWidth={false}
          style={{
            height: "100vh",
            overflow:"hidden",
          }}
        >
          <View style={{backgroundColor:'#594FF5',height:76,borderBottomColor:'#000',borderBottomWidth:3,borderStyle:"solid"}}></View>
        <Grid
          container
          style={{
            height: "88.9%",
          }}
        >
          <Grid
            item
            sm={6}
            style={{
              backgroundColor: "#F6F0FF",
              borderRightWidth:3,
              borderRightColor:'#000',borderRightStyle:"solid",
              overflow: "hidden",
            }}
          >
            <View style={{height:'68%',alignItems:'center',justifyContent:'center'}}>
                <img
                  alt="carousel-right-tumbnail"
                  style={{width:'80%'}}
                  src={imgBanner}
                />
            </View>
          
            <View style={styles.staticBottomContent}>
              <img
                src={imgLogo}
                alt="mainImg"
                style={{ height: "52px", marginBottom: "30px" }}
              />
              <Typography
                style={{
                  color: "#000",
                  lineHeight: "26px",
                  textAlign: "center",
                  fontFamily: 'CircularStd',
                  fontSize: "16px",
                }}
              >
                {configJSON.supportText}
              </Typography>
            </View>
          
          </Grid>
          <Grid
             item
            sm={6}
            alignItems="center"
            container
            style={{
              height: "100%",
              flexDirection: "column",
              overflowY: "scroll",
              padding:"50px 0 "
            }}
          >
            <Box sx={{ width: "407px"}}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px 0px",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h4"
                  component="h2"
                  style={{
                    fontSize: 30,
                    color: "#000000ff",
                    justifyContent: "center",
                    marginBottom: "30px",
                    display: "flex",
                    fontFamily:'CircularStdBold',
                    fontWeight:600
                  }}
                >
                  {configJSON.contactUs}
                </Typography>
                <Formik
                    initialValues={{ first_name: "", last_name: "",email:"",phone_number:"",company_name:"",details:"",subject:""}}
                    validationSchema={Yup.object().shape(
                      this.state.contactFormSchema
                    )}
                    validateOnMount={true}
                    validateOnChange={true}
                    enableReinitialize
                    onSubmit={(values, actions) => {
                      this.submitContactDetails(values)
                      actions.setSubmitting(false);
                    }}
                    data-testid="contactForm"
                  >
                    {({ setFieldValue, values, handleSubmit, touched, errors }) => (
                      <form onSubmit={handleSubmit} className="mt-5 mb-2" style={{width:'100%'}}>
                         <View style={{ width: "100%" }}>

                         {((touched.first_name && errors.first_name) || (touched.last_name && errors.last_name) || (touched.email && errors.email)) && (
                          <AlertBox data-testid="alerMessage">
                             <>
                             {errors.first_name ? <div>{errors.first_name}</div>  : ''} 
                             {errors.last_name ? <div>{errors.last_name}</div>  : ''} 
                             {errors.email ? <div>{errors.email}</div>  : ''} 
                             </>
                          </AlertBox>
                        )}

                        {this.state.errorFailureMessage.length > 0 && (
                          <AlertBox data-testid="alerMessage">
                            {this.state.errorFailureMessage}
                          </AlertBox>
                        )}

                         <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <Text style={styles.customLabel}>{configJSON.lableFirstName}</Text>
                              <CustomFormWrapper>
                                <CustomInputStyle
                                  data-test-id="txtFname"
                                  placeholder={"First Name"}
                                  fullWidth={true}
                                  name="first_name"
                                  value={values.first_name}
                                  onChange={(event) => {setFieldValue('first_name',event.target.value);this.resetErrorMessage()}}
                                />
                              </CustomFormWrapper>
                            </Grid>
                            <Grid item xs={6}>
                              <Text style={styles.customLabel}>{configJSON.lableLastName}</Text>
                              <CustomFormWrapper>
                                <CustomInputStyle
                                  data-test-id="txtLname"
                                  placeholder={"Last Name"}
                                  fullWidth={true}
                                  name="last_name"
                                  value={values.last_name}
                                  onChange={(event) => {setFieldValue('last_name',event.target.value);this.resetErrorMessage()}}
                                />
                              </CustomFormWrapper>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} style={{ marginTop: 20 }}>
                            <Text style={styles.customLabel}>{configJSON.lableEmail}</Text>
                            <CustomFormWrapper>
                              <CustomInputStyle
                                data-test-id="email-id"
                                placeholder={"Your email"}
                                fullWidth={true}
                                name="email"
                                value={values.email}
                                onChange={(event) => {setFieldValue('email',event.target.value);this.resetErrorMessage()}}
                              />
                            </CustomFormWrapper>
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: 20 }}>
                            <Text style={styles.customLabel}>{configJSON.lablePhoneNumber}</Text>
                            <CustomFormWrapper>
                              <CustomInputStyle
                                data-test-id="phone-number"
                                placeholder={"Your Number"}
                                fullWidth={true}
                                name="phone_number"
                                value={values.phone_number}
                                onChange={(event) => {setFieldValue('phone_number',event.target.value);this.resetErrorMessage()}}
                              />
                            </CustomFormWrapper>
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: 20 }}>
                            <Text style={styles.customLabel}>{configJSON.lableCompanyName}</Text>
                            <CustomFormWrapper>
                              <CustomInputStyle
                                data-test-id="componyName"
                                placeholder={"Company"}
                                fullWidth={true}
                                name="subject"
                                value={values.subject}
                                onChange={(event) => {setFieldValue('subject',event.target.value);this.resetErrorMessage()}}
                              />
                            </CustomFormWrapper>
                          </Grid>

                          <Grid item xs={12} style={{ marginTop: 20 }}>
                            <Text style={styles.customLabel}>{configJSON.lableHelpDescription}</Text>
                            <CustomFormWrapper>
                                <CustomTextInputStyle
                                  minRows={1}
                                  data-test-id="txtInputHelpDescription"
                                  name="subject"
                                  value={values.details}
                                  style={{
                                    paddingTop:10,
                                    resize:"vertical",
                                    paddingBottom:10
                                  }}
                                  onChange={(event:any) => {
                                    setFieldValue('details',event.target.value);
                                    this.setTextcounter(event.target.value.length);
                                    this.resetErrorMessage()
                                  }}
                               
                                />
                               <Box style={{ marginRight: 10 ,color:"5A5A5A",fontSize:12, fontFamily:'CircularStd',position:"absolute",right:0,top:13,zIndex:2}}>{this.state.textcounter}/1000</Box>

                            </CustomFormWrapper>
                          </Grid>

                          <Grid container spacing={3} style={{ marginTop: 5,alignItems:"center" }}>
                            <Grid item xs={6}>
                              <Text style={styles.customLabel}>{configJSON.lableAttachBrief}</Text>
                              <CustomFormWrapper>
                                <CustomButtonOutline />
                              
                                <SubmitButton
                                  data-testid={"chooseFile"}
                                > 
                                 <input
                                    type="file"
                                    hidden
                                  />
                                  {configJSON.lableAttachBriefButtonContent}
                                </SubmitButton>
                            </CustomFormWrapper>
                            </Grid>
                            <Grid item xs={6} style={{paddingTop:30}}>
                              <Text style={styles.customLabel}>{configJSON.lableAttachBriefCustomContent}</Text>
                            </Grid>
                          </Grid>
                          <Grid item xs={12 } style={{paddingTop:30}}>
                            <CustomFormWrapper>
                              <CustomButtonOutline />
                              <SubmitButton
                                data-testid={"submitBtn"}
                                type="submit"
                              >
                                {configJSON.lableSubmitButton}
                              </SubmitButton>
                            </CustomFormWrapper>
                          </Grid>
                         </View>
                      </form>
                    )}
                  </Formik>
              </Box>
            </Box>
          </Grid> 
        </Grid>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CustomButtonOutline = styled("div")({
  bottom: '2px',
  width: "98%",
  backgroundColor: "#594FF5",
  zIndex: 0,
  border: "solid 3px #000",
  height: 33,
  position: "absolute",
  right: "-5px",
});
const AlertBox = styled("div")({
  border:0,
  borderLeft:4,
  boxSizing:'border-box',
  borderTopLeftRadius:5,
  borderBottomLeftRadius:5,
  fontFamily: 'CircularStd',
  padding:20,
  backgroundColor: "#FEE2E2",
  borderLeftColor:"#FF5B74",
  borderStyle:"solid",
  marginBottom:30,
  color:"#FF5B74",
  width:'100%',
});

const SubmitButton = styled(Button)({
 
  fontSize: "14px",
  width: "100%",
  padding: "4px",
  backgroundColor: "#fff",
  color: "#000",
  border: "solid 3px #000",
  borderRadius: 0,
  textTransform: "none",
  fontFamily:'CircularStdBold', 
  "&:focus": {
    backgroundColor: "#DEDCFD",
  },
  "&:hover": {
    backgroundColor: "#BDB9FB",
  },
 
});


const styles = StyleSheet.create({
  customLabel: {
    fontSize: 16,
    color: "#000",
    marginBottom: 5,
    display: "flex",
     fontFamily: 'CircularStdBold',
  },
  staticBottomContent: {
    maxWidth:'596px',
    alignItems: "center",
    padding: "0 20px",
    marginLeft: "auto",
    marginRight:"auto",
  }
});

const CustomInputStyle = styled(Input)({
  "& .MuiInputBase-input": {
    backgroundColor: "#fff",
    height: "22px",
    position: "relative",
    zIndex: 1,
    borderColor: "#000",
    width: "100%",
    paddingLeft: "5px",
    paddingRight: "5px",
    borderStyle: "solid",
    borderWidth: 3,
    fontFamily:'CircularStd', 
    fontSize:14,
    lineHeight:5
  },
  "& .MuiInputBase-fullWidth": {
    margin: 0
  },
  "& .MuiInputAdornment-positionEnd": {
    position:"absolute",
    zIndex:1,
    right:0,
  },
  "& inpur:before": {
    content: "none"
  },


});
const CustomTextInputStyle = styled(TextareaAutosize )({
  backgroundColor: "#fff",
  minHeight: "22px",
  position: "relative",
  zIndex: 1,
  borderColor: "#000",
  width: "100%",
  paddingLeft: "5px",
  paddingRight: "5px",
  borderStyle: "solid",
  borderWidth: 3,
  fontFamily:'CircularStd', 
  fontSize:14,
  paddingTop:30,
  lineHeight:1,
  "& .MuiInputBase-fullWidth": {
    margin: 0
  },

  "& inpur:before": {
    content: "none"
  },
  "& .MuiInputAdornment-positionEnd": {
    position:"absolute",
    zIndex:1,
    right:0,
  }

});


// Customizable Area End
