import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';

const ErrorPage = () => {
  return (
    <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    marginTop="0px"
  >
    <Typography variant="h1">404</Typography>
    <Box marginBottom="50px" textAlign="center">
      <Typography variant="h5">Slow or no internet connection.</Typography>
      <Typography variant="body1">
        Please check your internet settings.
      </Typography>
    </Box>
    <Button onClick={() => window.location.reload()} variant="outlined">
      Try again
    </Button>
  </Box>
  );
};

export default ErrorPage;
