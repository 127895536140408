Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.lableHeaderLogin = "Sign In";
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.supportText_1 = "Simplify, standardise, and streamline the quoting process using our central booking platform."
exports.supportText_2 = "Invite your team into your personalised dashboard with bespoke packages, a full catalogue of services plus add-ons, and instant project quotations. For more complex enquiries, you’re only one click away from speaking to your Account Manager!"
exports.privacyText = "London Filmed’s Privacy Statement";
exports.termsService = "Terms of Service.";
exports.forgotPassword = "Forgot Password?";
exports.loginSignInEndPt = "bx_block_login/login";
exports.termsServiceApi = "bx_block_terms_and_conditions/t_and_cs";
exports.privacyStatementApi = "bx_block_terms_and_conditions/privacy_statements";
exports.dummyText = " Contrary to popular belief, Lorem Ipsum is not simply random text. It";
exports.pleaseEnterValidEmail = "Please enter a valid Email ID.";
exports.pleaseEnterAPassword = "Please enter a password";
exports.emailLabel = "Email Address";
// Customizable Area End