export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");

export const imgLogo = require("../assets/logo.png");
export const imgUser = require("../assets/user.png");
export const imgNotification = require("../assets/notification.png");
export const createImage = require("../assets/create.png");
export const imgRightArrow = require("../assets/arrow.svg");
export const imgEnquire = require("../assets/enquire_bg.png");
export const imgClose =  require("../assets/close.png");
export const carouselArrow =  require("../assets/carouselArrow.svg");
export const checkBox =  require("../assets/checkbox.png");
export const dummySubcategoryImage =  require("../assets/halfDay.png");
export const fullDayImage =  require("../assets/fullDay.png");
export const group_plus = require("../assets/group_plus.svg");
