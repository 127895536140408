import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
    TextField,
  } from "@material-ui/core";
type MyProps = { testID:string,inputName:string, inputLabel: string; changeHandler: (event:any) => void };

export default class CustomCheckBox extends Component<MyProps> {
  static propTypes = {
    testID: PropTypes.string,
    inputName:PropTypes.string,
    inputLabel:PropTypes.string,
    changeHandler: PropTypes.func.isRequired
  };

  constructor(props: any) {
    super(props);

   
  }

  render() {
   
    const { testID } = this.props;

    return (
        <>
        <div style={{ marginBottom: "5px", fontSize: "16px",fontFamily: "CircularStdBold"}}>
           {this.props.inputLabel}
        </div>
        <TextField
            data-test-id={testID}
            className="customInput"
            fullWidth
            variant="outlined"
            inputProps={{
            style: {
                height: "0px"
            }
            }}
            name={this.props.inputName}
            onChange={(event) => {
                this.props.changeHandler(event)
            }}
        />
        </>
    );
  }


}
