Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";


exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.labelTitle = "Log in";
exports.lableHeaderLogin = "Sign In";
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.supportText = "We support talent, develop creativity and promote London as a global production hub - in film, television, animation, games and beyond";
exports.privacyText = "London Filmed’s Privacy Statement";
exports.termsService = "Terms of Service.";
exports.forgotPassword = "Forgot Password?";
exports.loginSignInEndPt = "bx_block_login/login";
exports.dummyText = " Contrary to popular belief, Lorem Ipsum is not simply random text. It";
exports.contactUs = "Contact us";
exports.lableFirstName ='First Name*'
exports.lableLastName ='Last Name*'
exports.lableEmail ='Email Id*'
exports.lablePhoneNumber ='Phone Number'
exports.lableCompanyName ='Company Name'
exports.lableHelpDescription ='Tell us how we can help in few words'
exports.lableAttachBrief ='Attach brief'
exports.lableAttachBriefButtonContent ='Choose file'
exports.lableAttachBriefCustomContent ='No file chosen'
exports.lableSubmitButton='Submit'
exports.fnameRequired='First name required'
exports.lnameRequired='Last name required'
exports.emailRequired='Please enter a valid Email ID'
exports.emailBlockEndpint='bx_block_contact_us/contacts'
// Customizable Area End