Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateAccountProfileEndPoint = "/bx_block_profile/profiles/update_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.getUserProfileEndPoint = "bx_block_profile/profiles";
exports.updateCustomizableProfileConfirmEndPoint ="/bx_block_profile/profiles/popup_confirmation"
exports.getViewUserProfileEndPoint = "account_block/accounts/specific_account";
exports.fnameRequired='Required: First name required';
exports.lnameRequired='Required: Last name required'
exports.maxFirstLastName='Max: Please reduce your input to 20 characters or fewer.'
exports.emailRequired=' Please enter a valid Email ID'
exports.phoneRequired='Phone number required';
exports.jobRequired= 'Job title is required';
exports.locationRequired='Location is required'
// Customizable Area End
