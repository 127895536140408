import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createProfile } from "../../customisableuserprofiles/src/assets";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading:boolean;
  userProfilePicture:string;
  companyProfilePicture:string;
  userProfileDatafirstName:string;
  userProfileDataJobTitle:string;
  Invoices:any;
  Enquires:any;
  ApprovedEnquiries:any;
  teamMembers:any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileAccountID: string = "";
  getInvoiceCallId:string| Message="";
  getCategoriesApiCallId:string="";
  getApprovedCategoriesApiCallId:string="";
  getTeamMemberListCallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      isLoading:true,
      userProfilePicture:"",
      userProfileDatafirstName:"",
      userProfileDataJobTitle:"",
      Invoices:"",
      Enquires:"",
      teamMembers:"",
      ApprovedEnquiries:"",
      companyProfilePicture:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
      if (apiRequestCallId === this.getProfileAccountID) {
        if (responseJson.data) {
          const { attributes } = responseJson.data;
          this.setState({
            userProfilePicture: attributes.profile_picture,
            userProfileDatafirstName: attributes.first_name,
            userProfileDataJobTitle: attributes.job_title,
            companyProfilePicture:attributes.company_logo
           
          });
          this.checkForClientAdmin(responseJson.data);
        }
      } else if (apiRequestCallId === this.getInvoiceCallId) {
     
        this.handleInvoicesMembers(responseJson)
       
      } else if (apiRequestCallId === this.getCategoriesApiCallId) {
       
        this.handleEnquiriesMembers(responseJson)
        
      }
      else if (apiRequestCallId === this.getApprovedCategoriesApiCallId) {
   
          this.setState({ApprovedEnquiries:responseJson?.inquiries?.data?.length||0})
       
      } else if (apiRequestCallId === this.getTeamMemberListCallId) {
        this.handleteamMembers(responseJson)
      
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    await this.fetchProfileData();
    await this.getInvoices();
    await this.getcategoryList();
    await this.getapprovedcategoryList();
  }

  handleteamMembers(responseJson:any){
    if (responseJson.company_users) {
      this.setState({ teamMembers: responseJson.company_users?.data?.length||0});
    }
  }

  handleInvoicesMembers(responseJson:any){
    if (responseJson) {
      this.setState({ Invoices: responseJson.invoices?.Invoices?.length||0 })
      }
  }

  handleEnquiriesMembers(responseJson:any){
    if(responseJson){
      this.setState({Enquires:responseJson?.inquiries?.data?.length||0,isLoading:false})
    } 
  }

  checkForClientAdmin = (data: {attributes:{type:string}}) =>{
    if(data.attributes.type === "ClientAdmin"){
      this.getTeamMemberListApi();
    }
  }


  getInvoices = () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInvoiceCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInvoicesEndPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  fetchProfileData =  () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('authToken')
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getViewUserProfileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getcategoryList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:  localStorage.getItem('authToken')
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
      this.getCategoriesApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/inquiries?status=`
        );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getapprovedcategoryList = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:  localStorage.getItem('authToken')
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
      this.getApprovedCategoriesApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/inquiries?status=approved`
        );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getTeamMemberListApi = () => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:  localStorage.getItem('authToken')
    };

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTeamMemberListCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllTeamMemberEndPoint
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true;
  };

  selectViewProfile = () => {
    let profileImageSrc;
  
    if (this.state.userProfilePicture!="No File Attached") {
      profileImageSrc =this.state.userProfilePicture;
    } else {
      profileImageSrc = createProfile; 
    }
    return profileImageSrc; 
  };
  selectViewCompanyProfile = () => {
    let profileImageSrc;
  
    if (this.state.companyProfilePicture!="No File Attached") {
      profileImageSrc =this.state.companyProfilePicture;
    } else {
      profileImageSrc = createProfile; 
    }
    return profileImageSrc; 
  };

  async refreshHome(){
    this.setState({isLoading:true})
    await this.fetchProfileData();
    await this.getInvoices();
    await this.getcategoryList();
    await this.getapprovedcategoryList();
  }

  goToCategory=()=> {
    this.props.navigation.navigate("Categoriessubcategories");
  }
  goToHelpCenterLandingPage=()=> {
    this.props.navigation.navigate("HelpCenter");
  }

  goToProfileLandingPage=()=> {
    this.props.navigation.navigate("CustomisableuserProfiles");
  }

  goToInvoicePage=()=>  {
    this.props.navigation.navigate("InvoiceBilling");
  }

  goToTeamMembersPage=()=>  {
    this.props.navigation.navigate("TeamBuilderList");
  }
  
  goToApprovedEnquires=()=>  {
    localStorage.removeItem("enqiry")
    localStorage.setItem("enqiry","approved")
    this.props.navigation.navigate("Enquiries");
  }
  goToHomeLandingPage=()=>  {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  goToEnquiry=()=>  {
    localStorage.removeItem("enqiry")
    localStorage.setItem("enqiry","")
    this.props.navigation.navigate("Enquiries");
  }
  
  goToQuote =()=> {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Categoriessubcategories");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToSettingsLandingPage 
  =()=> 
  {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Settings2");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getJobTitle = (inputName:string) => inputName.length > 38 ? `${inputName.slice(0, 38)}...` : inputName;
  // Customizable Area End
}
