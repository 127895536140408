// Customizable Area Start
export const deleteIcon = require("../assets/delete.png");
export const imgLogo = require("../assets/logo.png");
export const createImage = require("../assets/create.png");
export const imgNotification = require("../assets/notification.png");
export const imgUser = require("../assets/user.png");
export const imgRightArrow = require("../assets/arrow.svg");
export const createProfile = require("../assets/createProfile.png")
export const profile = require("../assets/profile.png");
export const group_plus = require("../assets/group_plus.svg");
export const group_edit = require("../assets/group_editbutton.svg")
// Customizable Area End
