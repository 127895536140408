Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.getAccountDetailsEndPoint = "account_block/accounts/specific_account";
exports.putNotificationEndPoint = "account_block/accounts/update_for_notification";
exports.exampleAPIMethodPut = "PUT";
exports.logoutEndPoint = "bx_block_login/logout";
exports.exampleAPIMethodDelete = "DELETE";
exports.changeAccountDetailEndPoint = "bx_block_profile/profiles/update_profile";
exports.changePasswordEndPt = "account_block/accounts/reset_password";
exports.reenterLabelPassword = "Re-Enter Your New Password";
exports.confirmationPopEndPoint = "bx_block_profile/profiles/popup_confirmation";
exports.msaEndpoint = "/bx_block_settings/msa_details";
exports.termsServiceApi = "bx_block_terms_and_conditions/t_and_cs";
exports.privacyStatementApi = "bx_block_terms_and_conditions/privacy_statements";
// Customizable Area End