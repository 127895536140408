import React from "react";
import { View } from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { createImage, group_plus, imgLogo, imgNotification } from "../../blocks/categoriessubcategories/src/assets";
import { Button, Container, Typography } from "@material-ui/core";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import AppHeader from "./AppHeader.web";

interface Props {
  navigate: any;
}

interface S {
}

interface SS {
}

export default class PageNotFound extends BlockComponent<Props, S, SS> {
  
  constructor(props: any){
    super(props)
  }

  goToLandingPage=()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToHome = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToProfileCustom() {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "CustomisableuserProfiles");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  goToHelpCenterCustom() {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "HelpCenter");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToEnquiryCustom = () => {
    localStorage.removeItem("enqiry");
    localStorage.setItem("enqiry", "");
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Enquiries");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToSettingsCustom(){
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Settings2");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    }

    goToQuoteCustom = () =>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Categoriessubcategories");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    }

  render() {
    return (
      <View>
        <Container
        disableGutters
        maxWidth={false}
        style={{
          height: "100vh",
          backgroundColor: '#F7F6FF',
          display:'flex',
          flexDirection:'column'
        }}
        >
          <AppHeader
              logoUrl={ imgLogo }
              homeText="Home"
              createImageUrl={ createImage }
              helpCenterText="Help Centre"
              userUrl={ createImage }
              settingsIcon
              quoteUrl={ group_plus }
              helpCenterNotificationUrl={ imgNotification }
              onPressHome={ () => this.goToHome() }
              onClickViewProfile={ () => this.goToProfileCustom() }
              onClickHelpCenter={() => this.goToHelpCenterCustom() }
              onClickViewEnquiries={() => this.goToEnquiryCustom() }
              onClickSettings={() => this.goToSettingsCustom() }
              onClickQuote={ () => this.goToQuoteCustom() }
          />
          <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',flexGrow:1}}>
          <div style={{display:'flex',flexDirection:'column',alignItems:'center',gap:'70px'}}>
              <Typography style={{fontFamily: "CircularStd", fontSize: 100, fontWeight: 700, lineHeight: '56px',letterSpacing: '-0.015em',textAlign: 'left',color:'#594FF5'}}>404</Typography>
              <Typography style={{fontFamily: "CircularStdBold", fontSize: "48px", fontWeight: 700, marginTop:'10px', lineHeight: '28px', textAlign: 'left', color: '#000000'}}>Oops!</Typography>
              <Typography style={{fontFamily: 'CircularStd', fontSize: '20px', fontWeight: 450, lineHeight: '28px', textAlign: 'center',}}>The page you're looking for seems to be missing. Let's get you back on track! </Typography>
              <div className="buttonWrapper w-195 btn-yellow w-195">
              <div className="buttonOutline" style={{ backgroundColor: "#FFC500" }}></div>
              <Button data-testid="resetButton" className="btn-sm" onClick={this.goToLandingPage}>Back to Home</Button>
              </div>
          </div>
          </div>
        </Container>
      </View>
    );
  }
}
