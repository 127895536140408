// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import DownloadOptions from "../../blocks/downloadoptions/src/DownloadOptions";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import ICalendarSupport from "../../blocks/icalendarsupport/src/ICalendarSupport.web";
import PrivacySettings from "../../blocks/privacysettings/src/PrivacySettings";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import HelpCenter from "../../blocks/helpcentre/src/HelpCenter.web"
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories.web";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import Multilevelapproval2 from "../../blocks/multilevelapproval2/src/Multilevelapproval2";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Cfapixero from "../../blocks/cfapixero/src/Cfapixero";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Reservations from "../../blocks/reservations/src/Reservations";
import CustomForm from "../../blocks/customform/src/CustomForm.web";
import CustomisableuserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web"
import EditProfile from "../../blocks/customisableuserprofiles/src/EditProfile.web"
import Enquiries from "../../blocks/customform/src/Enquiries.web"
import TeamBuilderList from "../../blocks/teambuilder/src/TeamBuilderList.web";
import { Switch } from "react-router-dom";
import AuthenticationDenied from "../../components/src/AuthenticationDenied";
import PageNotFound from "../../components/src/PageNotFound";

const routeMap = {
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
ICalendarSupport:{
 component:ICalendarSupport,
path:"/ICalendarSupport"},
PrivacySettings:{
 component:PrivacySettings,
path:"/PrivacySettings"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CustomForm:{
  component:CustomForm,
 path:"/request-quote/:id"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCenter:{
  component:HelpCenter,
  path:"/HelpCenter"
 },
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
EditProfile:{
  component:EditProfile,
path:"/EditProfile"
},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/login"},
Multilevelapproval2:{
 component:Multilevelapproval2,
path:"/Multilevelapproval2"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Cfapixero:{
 component:Cfapixero,
path:"/Cfapixero"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Reservations:{
 component:Reservations,
path:"/Reservations"},
CustomisableuserProfiles:{
 component:CustomisableuserProfiles,
path:"/CustomisableuserProfiles"},

TeamBuilderList:{
  component:TeamBuilderList,
 path:"/TeamBuilderList"},

  Home: {
    component: EmailAccountLoginBlock,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  Enquiries: {
    component: Enquiries,
    path: "/Enquiries",
  },
  AuthenticationDenied: {
    component: AuthenticationDenied, 
    path: "/AccessDenied"
  },
  PageNotFound:{ 
    path: "/*",
    component: PageNotFound
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100%' }}>
          <Switch>
            {WebRoutesGenerator({ routeMap })}
          </Switch>
        <ModalContainer />
      </View>
    );
  }
}

export default App;
