import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  contactFormSchema:any;
  textcounter:number;
  token:string;
  errorFailureMessage:string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  validationApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    let contactFormSchema = {
      first_name: Yup.string()
            .matches(/^[A-Za-z ]*$/, 'Please enter First name')
            .max(20)
            .required(configJSON.fnameRequired),
      last_name: Yup.string()
            .matches(/^[A-Za-z ]*$/, 'Please enter Last name')
            .max(20)
            .required(configJSON.lnameRequired),
      email: Yup.string()
          .email(configJSON.emailRequired)
          .required(configJSON.emailRequired),
   
    };


    this.state = {
      name: "",
      contactFormSchema: contactFormSchema,
      textcounter:0,
      token:"",
      errorFailureMessage:"",
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
   
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  setTextcounter = (count:any) => {
    this.setState({textcounter:count})
  }
  // Customizable Area End


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      if(token == undefined) {
        token = await getStorageData("authToken")
      }
      runEngine.debugLog("TOKEN", token);

      this.setState({ token: token });
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.validationApiCallId &&
      this.validationApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) 
    )  {


      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.data) {
          this.setState({errorFailureMessage:"Your query has been submitted successfully"})
       } else if (responseJson && responseJson.errors) {
            responseJson.errors.forEach((error: any) => {
              if (error.contact) {
                this.setState({errorFailureMessage: error.contact.join(".")});
              }
              if(error.message) {
                this.setState({errorFailureMessage: "Invalid Email Id, Please try again"});
              }
            });
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start


  resetErrorMessage = () => {
    this.setState({ errorFailureMessage: '' });
  };


  submitContactDetails(dataVal:any) {
    let data = Object.fromEntries(
      Object.entries(dataVal).filter(([key, v]) => v !== ''),
    )
   let body ={data:data}
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.validationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailBlockEndpint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  
  // Customizable Area End
}
