import React from "react";
import { View } from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { imgLogo } from "../../blocks/categoriessubcategories/src/assets";
import { Box, Button, Container, Typography } from "@material-ui/core";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";

interface Props {
  navigate: any;
}

interface S {
}

interface SS {
}

export default class AuthenticationDenied extends BlockComponent<Props, S, SS> {
  
  constructor(props: any){
    super(props)  
      
  }

  goToLogin=()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  render() {
    return (
      <View>
        <Container
        disableGutters
        maxWidth={false}
        style={{
          height: "100vh",
          backgroundColor: '#F7F6FF',
          display:'flex',
          flexDirection:'column'
        }}
        >
          <Box style={{display:'flex',alignItems:'center', height:'76px',width:'100%',backgroundColor:'#594FF5',paddingLeft:'40px',boxSizing:'border-box'}}>
          <img
            style={{
              backgroundColor: "00FFFFFF",
              height: 52,
              marginRight: 40,
              cursor: "pointer"
            }}
            src={imgLogo}
            alt="Logo"
          />
          </Box>
          <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',flexGrow:1}}>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',gap:'70px'}}>
              <Typography style={{fontFamily: "CircularStd", fontSize: 100, fontWeight: 700, lineHeight: '56px',letterSpacing: '-0.015em',textAlign: 'left',color:'#594FF5'}}>403</Typography>
              <Typography style={{fontFamily: "CircularStdBold", fontSize: "48px", fontWeight: 700, marginTop:'10px', lineHeight: '28px', textAlign: 'left', color: '#000000'}}>Access Denied!</Typography>
              <Typography style={{fontFamily: 'CircularStd', fontSize: '20px', fontWeight: 450, lineHeight: '28px', textAlign: 'center',}}>Oops! You've encountered a forbidden zone. Looks like this area is off-limits.</Typography>
              <div className="buttonWrapper w-195 btn-yellow w-195">
                <div className="buttonOutline" style={{ backgroundColor: "#FFC500" }}></div>
                <Button data-testid="resetButton" className="btn-sm" onClick={this.goToLogin}>Back to Login</Button>
              </div>
            </div>
          </div>
        </Container>    
      </View>
    );
  }
}
