import React, { FunctionComponent, } from "react";
import SettingsIcon from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/Add';
import {
  StyleSheet,
  Text,
  View,
} from "react-native";
import {
  Container,
  Grid,SwipeableDrawer,Box, Typography, Backdrop
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
const iconNotification =  require("../../blocks/settings2/assets/1043817464cFxzonYRDJMRVgjEbjIYjo.png");
const iconUpArrow = require("./iconUpArrow.png");
const iconMenu = require("./iconMenu.png");
import { createProfile } from "../../blocks/customisableuserprofiles/src/assets";
import { imgClock}  from "../../blocks/landingpage/src/assets";
const downArrow = require("./iconUpArrow.png");

interface Props {
  helpCenterText?: string;
  logoUrl: string;
  homeText: string;
  createImageUrl: string;
  notificationUrl?: string;
  helpCenterNotificationUrl?: string
  userUrl: string;
  settingsIcon?: boolean;
  onPressHome: () => void;
  onClickHelpCenter: () => void;
  onClickViewProfile: () => void;
  onClickViewEnquiries?: () => void;
  onClickSettings?: () => void;
  onClickQuote?: () => void;
  quoteUrl?: any;
}

const Header: FunctionComponent<Props> = (props) => {

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const [openNotification,setNotification] = React.useState(false);
  const toggleDrawer=()=>{
    setDrawerOpen(!isDrawerOpen);
  };
  const fnNotification =()=>{
    setNotification(prev => !prev);
  }

  const notificationData = [{image:createProfile,userName:"Agora",packageName:"Live Streaming",packageDate:"01/12/2022",packageStatus:"Approved",time:"2h"},{image:createProfile,userName:"Agora",packageName:"Live Streaming",packageDate:"01/12/2022",packageStatus:"Approved",time:"2h"},{image:createProfile,userName:"Agora",packageName:"Live Streaming",packageDate:"01/12/2022",packageStatus:"Approved",time:"2h"}]

  const notificationData1 = [{image:createProfile,userName:"Agora",packageName:"Live Streaming",packageDate:"01/12/2022",packageStatus:"Approved",time:"2h"},{image:createProfile,userName:"Agora",packageName:"Live Streaming",packageDate:"01/12/2022",packageStatus:"Approved",time:"2h"}]


  return (
    <>
    <View
      style={{
        backgroundColor: '#594FF5',
        paddingTop: 10,
        paddingBottom: 10,
        borderBottomColor: "#000",
        borderBottomWidth: 3,
        borderStyle: "solid",
      }}
    >
      <CustomContainer disableGutters maxWidth={false}>
        <Grid container spacing={0} >
          <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
            <View
            style={{ alignItems: "center", justifyContent: "center" }}
            >
              <img
                style={{
                  backgroundColor: "00FFFFFF",
                  height: 52,
                  marginRight: 40,
                  cursor: "pointer"
                }}
                src={props.logoUrl}
                alt="Logo"
                onClick={props.onPressHome}
              />
            </View>
            <Text testID="homeNav" 
            style={styles.headerText} 
            onPress={props.onPressHome}>
              {props.homeText}
            </Text>
            <Text testID="enquiriesNav" style={styles.headerText} onPress={props.onClickViewEnquiries}>
              Enquiries
            </Text>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "self-end",
                justifyContent:"center",
                paddingRight: "30px"

              }}
            >
              <div onClick={props.onClickQuote}>
                <div>
                <img src={props.quoteUrl} style={{marginRight:"2px",height:"18px", width:"18px", marginTop:"2px"}}/>
                </div>
              </div>
              <div style={{
                color: "#f6f0ffff",
                fontSize: 16,
                fontWeight: 500,
                fontFamily: "CircularStdBold",cursor: 'pointer'
              }} onClick={props.onClickQuote} className="resHeaderQuote">Quote</div>
            </div>

            {props.notificationUrl && <div style={{ 
              cursor: 'pointer' }}>
              <img
                style={{
                  backgroundColor: "00FFFFFF",
                  height: 22,
                  width: 22,
                  cursor: 'pointer'
                }}
                src={props.notificationUrl}
                alt="image_notification"
              />
            </div>}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                cursor: 'pointer'
              }}
            >

              <div onClick={props.onClickViewProfile}>
                <img
                  style={{
                    backgroundColor: "00FFFFFF",
                    height: 22,
                    width: 22,
                    cursor: 'pointer',
                  }}
                  src={props.userUrl}
                  alt="image_create"
                />
              </div>
              <div style={{
                color: "#f6f0ffff",fontSize: 16, fontWeight: 500,fontFamily: "CircularStdBold", paddingLeft:"0px" , paddingRight:"30px"
                }} onClick={props.onClickViewProfile}>{localStorage.getItem('userName')}</div>


              {props.helpCenterNotificationUrl && <div style={{ 
                paddingRight:"30px",
                 cursor: 'pointer' }}>
                <img
                  style={{
                    backgroundColor: "00FFFFFF",
                    height: 22,
                    width: 22,
                    cursor: 'pointer'
                  }}
                  src={props.helpCenterNotificationUrl}
                  alt="image_notification"
                  onClick={fnNotification}
                />
              </div>}

              <div style={{
                color: "#f6f0ffff",
                fontSize: 16,
                fontWeight: 500,
                fontFamily: "CircularStdBold", paddingRight: "30px"
              }} onClick={props.onClickHelpCenter}>{props.helpCenterText}</div>


              {props.settingsIcon && <SettingsIcon style={{ color: "#fff",width:95 , height:20,marginLeft:"-2rem"}} onClick={props.onClickSettings} />}
            </div>
          </Grid>
        </Grid>
      </CustomContainer>
      <MobileHeader>
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <img
            style={{
              backgroundColor: "00FFFFFF",
              height: 40,
              cursor: "pointer"
            }}
            src={props.logoUrl}
            alt="Logo"
            onClick={props.onPressHome}
          />
          <div style={{ 
              cursor: 'pointer', display:"flex", alignItems:"center" }}>
              <img
                style={{
                  backgroundColor: "00FFFFFF",
                  height: 23,
                  width: 25,
                  cursor: 'pointer',
                  paddingRight:39,
                }}
                src={iconNotification}
                alt="image_notification"
                onClick={fnNotification}
              />
           
            <img src={iconMenu} onClick={toggleDrawer} style={{height:16}}/>
            </div>
            </div>
        </MobileHeader>
    </View>
    <CustomSwipeableDrawer anchor="top" open={isDrawerOpen} onClose={toggleDrawer} onOpen={toggleDrawer} >
        <MobileHeader style={{height:"65px",}}>
        <div style={{display:"flex",alignItems:"center", justifyContent:"space-between",paddingTop:"10",boxSizing:"border-box"}}>
          <img
            style={{
              backgroundColor: "00FFFFFF",
              height: 40,
              cursor: "pointer"
            }}
            src={props.logoUrl}
            alt="Logo"
            onClick={props.onPressHome}
          />
          <div style={{ 
              cursor: 'pointer', display:"flex", alignItems:"center" }}>
              <img
                style={{
                  backgroundColor: "00FFFFFF",
                  height: 23,
                  width: 25,
                  cursor: 'pointer',
                  paddingRight:39,
                }}
                src={iconNotification}
                alt="image_notification"
              />
           
            <img src={iconMenu} onClick={toggleDrawer} style={{height:16}}/>
            </div>
            </div>
        </MobileHeader>
          <div style={{ border:"3px solid black" , paddingTop:"15px" , paddingBottom:"12px" ,backgroundColor:"#DEDCFD", display:"flex", flexDirection:"column",gap:23, height:298 , fontWeight:700,boxSizing:"border-box",alignItems:"center", cursor: "pointer",transition: "height 1.9s ease-in-out" ,}}>
          <CustomList onClick={props.onClickViewProfile}>{localStorage.getItem('userName')}</CustomList>
            <CustomList onClick={props.onPressHome}>Home</CustomList>
            <CustomList onClick={props.onClickViewEnquiries}>Enquiries</CustomList>
            <CustomList onClick={props.onClickQuote}>Quote</CustomList>
            <CustomList onClick={props.onClickHelpCenter}>{props.helpCenterText}</CustomList>
            <CustomList onClick={props.onClickSettings}>Settings</CustomList>
            <div>
              <img src={iconUpArrow} onClick={() => setDrawerOpen(false)} style={{ height: 10, paddingTop:6 }} />
            </div>
          </div>

            </CustomSwipeableDrawer>
      <Backdrop
        style={{
          background: "transparent",
          zIndex: 1300, position: "absolute"
        }}
        open={openNotification}
        onClick={(e) => { setNotification(false) }
        }
      >
        <NotificationContainer>
          <div onClick={(event) =>
            event.stopPropagation()}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0 10 10 10", marginTop: "-9px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography style={{ fontFamily: "CircularStdBold", fontSize: 14, fontWeight: 450 }}>Notification</Typography>
                <Typography style={{ fontFamily: "CircularStd", fontSize: 16, fontWeight: 700 }}>&nbsp;({notificationData.length})</Typography>
              </div>
              <Typography style={{
                color: "#5A5A5A",
                fontSize: 14,
                fontFamily: "CircularStd",
                fontWeight: 450
              }}>Clear All</Typography>
            </div>
            <div style={{ padding: "0 5 0 5" }}>
              {notificationData.map((data) => {
                return (
                  <NotificationLayout>
                    <div
                      style={{ display: "flex", flexDirection: "row", }}
                    >
                      <img
                        src={data.image}
                        style={{
                          marginRight: 10,
                          borderColor: "#000",
                          borderWidth: 3,
                          width: 56,
                          height: 56,
                          border: "3px solid black",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flex: 1,
                          flexDirection: "row",
                        }}
                      >
                        <div>
                          <Typography style={{
                            fontSize: 14,
                            color: "#000",
                            fontFamily: "CircularStd", fontWeight: 700,
                          }}>{data.userName}</Typography>
                          <Typography style={{
                            color: "#5A5A5A",
                            fontSize: 14, fontWeight: 450,
                            fontFamily: "CircularStd"
                          }}>
                            {data.packageName}
                          </Typography>
                          <Typography style={{
                            color: "#5A5A5A",
                            fontSize: 14, fontWeight: 450,
                            fontFamily: "CircularStd"
                          }}>
                            {data.packageDate}
                          </Typography>


                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <Typography style={{
                            color: "#00BFBA",
                            fontSize: 16,
                            textAlign: "center",
                            fontWeight: 700,
                            fontFamily: "CircularStd"
                          }}>{data.packageStatus}</Typography>
                          <div
                            style={{
                              marginTop: "auto",
                              justifyContent: "flex-end",
                              display: "flex",
                            }}
                          >
                            <img
                              src={imgClock}
                              style={{ marginRight: "2px" }}
                            />
                            <Typography
                              style={{
                                color: "#64748B",
                                fontSize: 12,
                                marginRight: 0,
                                height: 9,
                                fontFamily: "CircularStd"
                              }}
                            >
                              {data.time}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NotificationLayout>
                )
              })}
            </div>
            <img src={downArrow}
              style={{ marginLeft: "50%", marginTop: 5 }}
              onClick={
                () => {
                  setNotification(false)
                }
              }
            />
          </div>
        </NotificationContainer>
      </Backdrop>

      <Backdrop
        style={{
          background: "transparent",
          zIndex: 1300, position: "absolute"
        }}
        open={openNotification}
        onClick={(e) => { setNotification(false) }
        }
      >
        <ResNotificationContainer>
          <div onClick={(event) =>
            event.stopPropagation()}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0 10 10 10", marginTop: "-9px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography style={{ fontFamily: "CircularStdBold", fontSize: 14, fontWeight: 450 }}>Notification</Typography>
                <Typography style={{ fontFamily: "CircularStd", fontSize: 16, fontWeight: 700 }}>&nbsp;({notificationData1.length})</Typography>
              </div>
              <Typography style={{
                color: "#5A5A5A",
                fontSize: 14,
                fontFamily: "CircularStd",
                fontWeight: 450
              }}>Clear All</Typography>
            </div>
            <div style={{ padding: "0 5 0 5" }}>
              {notificationData1.map((data) => {
                return (
                  <NotificationLayout>
                    <div
                      style={{ display: "flex", flexDirection: "row", }}
                    >
                      <img
                        src={data.image}
                        style={{
                          marginRight: 10,
                          borderColor: "#000",
                          borderWidth: 3,
                          width: 56,
                          height: 56,
                          border: "3px solid black",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flex: 1,
                          flexDirection: "row",
                        }}
                      >
                        <div>
                          <Typography style={{
                            fontSize: 14,
                            color: "#000",
                            fontFamily: "CircularStd", fontWeight: 700,
                          }}>{data.userName}</Typography>
                          <Typography style={{
                            color: "#5A5A5A",
                            fontSize: 14, fontWeight: 450,
                            fontFamily: "CircularStd"
                          }}>
                            {data.packageName}
                          </Typography>
                          <Typography style={{
                            color: "#5A5A5A",
                            fontSize: 14, fontWeight: 450,
                            fontFamily: "CircularStd"
                          }}>
                            {data.packageDate}
                          </Typography>


                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <Typography style={{
                            color: "#00BFBA",
                            fontSize: 16,
                            textAlign: "center",
                            fontWeight: 700,
                            fontFamily: "CircularStd"
                          }}>{data.packageStatus}</Typography>
                          <div
                            style={{
                              marginTop: "auto",
                              justifyContent: "flex-end",
                              display: "flex",
                            }}
                          >
                            <img
                              src={imgClock}
                              style={{ marginRight: "2px" }}
                            />
                            <Typography
                              style={{
                                color: "#64748B",
                                fontSize: 12,
                                marginRight: 0,
                                height: 9,
                                fontFamily: "CircularStd"
                              }}
                            >
                              {data.time}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NotificationLayout>
                )
              })}
            </div>
            <img src={downArrow}
              style={{ marginLeft: "50%", marginTop: 5 }}
              onClick={
                () => {
                  setNotification(false)
                }
              }
            />
          </div>
        </ResNotificationContainer>
      </Backdrop>
    </>
  );
};

export default Header;

const styles = StyleSheet.create({
  headerText: {
    color: "#f6f0ffff",
    fontSize: 16,
    letterSpacing: 0,
    paddingLeft: 10,
    marginLeft: 10,
    marginRight: 10,
    paddingRight: 10,
    fontFamily: "CircularStdBold",
    "@media(min-width:360px)":{
      display:"none",
      color:"black"
    }
  },
  textHeader: {
    color: "#f6f0ffff",
    fontSize: 16,
    fontWeight: "500",
    fontFamily: "CircularStdBold",
  },
  container: {
    width: "100%",
    backgroundColor: "#000",
    height: 44,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
  },
});


const CustomContainer = styled(Container)({
  paddingLeft: "40px", paddingRight: "5px",
    "@media screen and (min-width:320px) and (max-width:900px)":{
    display:"none",
  },
  "@media screen and (min-width:900px) and (max-width:1300px)":{
    display:"block",
  }
})

const MobileHeader = styled(`div`)({
  display:"none",
  "@media screen and (min-width:320px) and (max-width:900px)":{
    display:"block", 
    boxSizing: "border-box",
    padding : "4 8 4 8",
    backgroundColor:"#594FF5"
  },
  "@media screen and (min-width:900px) and (max-width:1300px)":{
    display:"none",
  }
})

const CustomSwipeableDrawer = styled(SwipeableDrawer)({
  display:"none",
  "@media screen and (min-width:320px) and (max-width:900px)":{display:"block", 
  },
  "@media screen and (min-width:900px) and (max-width:1300px)":{display:"none"}
})

const CustomList = styled(`div`)({
  "&:hover":{
    textDecoration: "underline",
    textDecorationColor:"1px solid black",
  }
})

const NotificationContainer  = styled(`div`)({
  width : 347,border:"3px solid black",height:367, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "32px",
  right: "144",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '72%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-393px",
  right:"-69.2%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },

  "@media screen and (min-width:320px) and (max-width:899px)":{display:"none"},
  "@media screen and (min-width:900px)":{
    width : 347,border:"3px solid black",height:367, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "32px",
  right: "144",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '72%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-393px",
  right:"-69.2%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:2555px)":{
    width : 347,border:"3px solid black",height:367, marginTop:40,
    backgroundColor:"#FDF8F8",
    position: "absolute",
    top: "32px",
    right: "144",
    '&::before' : {
      content: "''",
      position: 'relative',
    top: "-47px",
    left: '72%',
    transform: 'translateX(-50%)',
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderLeft: '15px solid transparent',
    borderRight: '15px solid transparent',
    borderBottom: '25px solid black',
    zIndex : -2,
    },
    '&::after':{
      content: "''",
    position: "relative",
    borderLeft: "15px solid transparent",
    borderRight: "15px solid transparent",
    top: "-397px",
    right:"-69.2%",
    marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  }
})

const ResNotificationContainer  = styled(`div`)({
  width : "100%",
    boxSizing: "border-box",
  border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '72%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "'W'",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-69.2%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  "@media screen and (min-width:300px)":{
    width : "100%",
    boxSizing: "border-box",
    border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '74%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-71.2%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:370px)":{
    width : "100%",
    boxSizing: "border-box",
    border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '75%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-72.3%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:380px)":{
    width : "100%",
    boxSizing: "border-box",
    border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '75%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-72.4%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:400px)":{
    width : "100%",
    boxSizing: "border-box",
    border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '76%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-73.3%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:403px)":{
    width : "100%",
    boxSizing: "border-box",
    border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '77%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-74.3%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:410px)":{
    width : "100%",
    boxSizing: "border-box",
    border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '77%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-74.6%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:420px)":{
    width : "100%",
    boxSizing: "border-box",
    border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '78%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-75.5%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:440px)":{
    width : "100%",
    boxSizing: "border-box",
    border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '79%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-76.7%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:460px)":{
    width : "100%",
    boxSizing: "border-box",
    border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '80%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-77.7%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:486px)":{
    width : "100%",
    boxSizing: "border-box",
    border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '81%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-78.9%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:500px)":{
    width : "100%",
    boxSizing: "border-box",
    border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '82%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-80.1%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:540px)":{
    width : "100%",
    boxSizing: "border-box",
    border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '83%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-81.2%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:580px)":{
    width : "100%",
    boxSizing: "border-box",
    border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '84%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-82.2%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:600px)":{
    width : "100%",
    boxSizing: "border-box",
    border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '85%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-83.3%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:650px)":{
    width : "100%",
    boxSizing: "border-box",
    border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '86%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-84.5%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:685px)":{
    width : "100%",
    boxSizing: "border-box",
    border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '87%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-85.6%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:740px)":{
    width : "100%",
    boxSizing: "border-box",
    border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '88%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-86.6%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:800px)":{
    width : "100%",
    boxSizing: "border-box",
    border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '89%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-87.6%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:890px)":{
    width : "100%",
    boxSizing: "border-box",
    border:"3px solid black",height:268, marginTop:40,
  backgroundColor:"#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before' : {
    content: "''",
    position: 'relative',
  top: "-43px",
  left: '90%',
  transform: 'translateX(-50%)',
  width: 0,
  height: 0,
  borderTop: '10px solid transparent',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '25px solid black',
  zIndex : -2,
  },
  '&::after':{
    content: "''",
  position: "relative",
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  top: "-293px",
  right:"-88.8%",
  marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  },
  "@media screen and (min-width:900px)":{
   display:"none"
  }
})


const NotificationLayout = styled(`div`)({
  padding: "8px 7px",
  backgroundColor: "#fff",
  borderRadius: 2,
  marginBottom: 20,
  boxShadow: "0px 10px 20px 0px #00000008",
  width : "auto",
});
