import React, { useState, useEffect } from 'react';
import { getStorageData } from '../../framework/src/Utilities';
import { Redirect } from 'react-router-dom'
import { setStorageData } from 'framework/src/Utilities';

const PrivateRoutes = (props: any) => {
  const [authLogin, setAuthLogin] = useState(true);

  useEffect(() => {
    let storage = null;
    const getAuthToken = async () => {
      storage = await getStorageData("authToken");
      const pathname = window.location.pathname;
      const search = window.location.search;
      if (storage !== null) {
        setAuthLogin(true)
      }
      else {
        setAuthLogin(false);
        if(!props.accessiblePathWithoutLgin){          
          await setStorageData("previousPath", pathname.includes(props.path.split('/')[0]) ? pathname + search : "/PageNotFound") // storing previous path in local storage so that I can redirect after login
        }
      }
    }

    getAuthToken();

  }, []);

  return !authLogin && !props.accessiblePathWithoutLgin && <Redirect to="/AccessDenied" />

};

export default PrivateRoutes;