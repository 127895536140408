export const triangle = require("../assets/triangle.png");
export const progressBar = require("../assets/Circle.png");
export const settingIcon = require("../assets/settings.png");
export const progressIcon = require("../assets/Progress.png");
export const mailIcon = require("../assets/mail.png");
export const qrCodeIcon = require("../assets/qrcode.png");
export const payPalLogo = require("../assets/paypal.png");
export const rightArrow = require("../assets/Icon.png");
export const imgLogo = require("../assets/logo.png");
export const imgBanner = require("../assets/homeBanner.svg");
export const imgUser = require("../assets/1043817461cFxzonYRDJMRVgjEbjIYjo.png");
export const imgNotification = require("../assets/1043817464cFxzonYRDJMRVgjEbjIYjo.png");
export const createImage = require("../assets/1043817466cFxzonYRDJMRVgjEbjIYjo.png");
export const group_plus = require("../assets/group_plus.svg");
