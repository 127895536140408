import React, { FunctionComponent} from "react";
import "./Switch.css"


interface Props {
isChecked: boolean;
toggleSwitch:()=> void;

}

const Switch: FunctionComponent<Props> = (props) => {

  return (
    <label className="switch">
    <input type="checkbox" 
    checked={props.isChecked} onChange={props.toggleSwitch} 
    />
    <span className="slider"></span>
  </label>
  );
};

export default Switch;
