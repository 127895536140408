export const imgLogo = require("../assets/logo.png");
export const imgUser = require("../assets/user.png");
export const imgNotification = require("../assets/notification.png");
export const createImage = require("../assets/create.png");
export const imgRightArrow = require("../assets/arrow.svg");
export const imgUpload = require("../assets/image_cloud_upload_24px.png");
export const imgDocument = require("../assets/image_document.png");
export const imgClose =  require("../assets/close.png");
export const loadingImg = require("../assets/Loader.gif")
export const group_plus = require("../assets/group_plus.svg");
export const googlecalicon = require("../assets/Vectorgoogle.svg");
export const windowscalicon = require("../assets/Vectorwindows.svg")
export const yahooIcon = require("../assets/Vectoryahoo.svg")
export const appleicon = require("../assets/Vectorapple.svg")
export const groupmeeticon = require("../assets/group_vectormeeting2.svg")
export const calendaricon = require("../assets/VectorCalendar.svg")
export const downloadImage = require("../assets/Downloadvector.svg");
