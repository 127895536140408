import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  View,
  Text,
  Image
} from "react-native";
import {
  Container,
  Button,
  Grid,
  Dialog,
  DialogContent,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Typography,
  Paper,
  TextField,
  Tooltip
} from "@material-ui/core";
import CategoriessubcategoriesWebController, {
  Props,
  configJSON
} from "./CategoriessubcategoriesController.web";
import  AppHeader  from "../../../components/src/AppHeader.web";
import { 
  imgLogo,
  createImage,
  imgRightArrow,
  imgClose,
  checkBox,
  carouselArrow,group_plus,
  imgNotification } from "./assets";
import GenericLabel from "../../../components/src/GenericLabel";
import Slider from "react-slick";
import { loadingImg } from "../../customform/src/assets";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import moment from "moment";
// Customizable Area End

export default class Categoriessubcategories extends CategoriessubcategoriesWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderCompanyLogo =(data:any)=>{
    if(data.attributes.service_category==="bespoke"&&this.state.companyProfilePicture){
      return(
        <img
        data-testid={`companyprofile-${data.id}`}
        src={this.selectViewCompanyProfile()}
        alt="profile"
        width="33"
        height="33"
        style={{
          border: "3px solid #000",
          width: 36,
          height: 36,
          objectFit: "cover",
          alignSelf: 'flex-start', 
          marginLeft: 10, 
        }}
      />
      )
  }}
  sortArr = (data:any) => {
    const sortedData: any = [...data].sort((a, b) => {
      if (a.attributes?.duration === null) return 1;
      if (b.attributes?.duration === null) return -1;
      return parseInt(a.attributes?.duration) - parseInt(b.attributes?.duration);
  });
    return sortedData || data
  }
  renderSortIcon = (column:any) => {
    if (this.state.sortDirection === 'asc') {
      return <KeyboardArrowDownIcon style={{ color: "#594FF5", verticalAlign: 'middle' }} />;
      } else if (this.state.sortDirection === 'desc') {
      if (this.state.hoverIndex === column) {
          return <KeyboardArrowUpIcon style={{ color: "#594FF5", verticalAlign: 'middle' }} />;
        }
      }
    return <KeyboardArrowDownIcon style={{ color: "#594FF5", verticalAlign: 'middle' }} />;
  };
  renderViewCustomFormPopupPriceDetails() { 
    return (
      <View style={{ backgroundColor: '#DEDCFD', 
      borderColor: '#000', borderWidth: 3, padding: 20, paddingTop: 10, paddingBottom: 10, width: 450 }}>
        <Text style={{ fontFamily: "CircularStdBold", color: "#000000", fontSize: 14, paddingBottom: 20 }}>
          {this.state?.customForm?.attributes 
          && this.state?.customForm?.attributes?.service_name}
        </Text>
        <View style={[styles.tableProvision, 
          { borderBottomColor: '#CBD5E1', borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5 }]}>
          <Text style=
          {styles.fontSm}>
            Provisional Cost</Text>
          <Text 
          style={styles.fontSm}>£
          {this.state?.customForm?.attributes?.cost_summery?.provisional_cost}</Text>
        </View>
        <View 
        style={[styles.tableProvision,
         { borderBottomColor: '#CBD5E1', borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5 }]}>
          <Text style={styles.fontSm}>
            Inc. Add-ons</Text>
          <Text 
          style={styles.fontSm}>
            £{this.state?.customForm?.attributes?.cost_summery?.provisional_addon_cost}</Text>
        </View>
        <View 
        style={[styles.tableProvision, { borderBottomColor: '#CBD5E1', 
        borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5 }]}>
          <Text 
          style={styles.fontSm}>Additional Services</Text>
          <Text 
          style={styles.fontSm}>
            £{this.state?.customForm?.attributes?.cost_summery?.additional_services_cost}</Text>
        </View>
        <View 
        style={[styles.tableProvision, { 
          borderBottomColor: '#CBD5E1', borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5 }]}>
          <Text 
          style={styles.fontSm}>
            Inc. Add-ons</Text>
          <Text 
          style={styles.fontSm}>£
          {this.state?.customForm?.attributes?.cost_summery?.additional_addons_cost}</Text>
        </View>
        <View style={[styles.tableProvision, 
          { paddingTop: 10 }]}>
          <Text style={{
             fontFamily: "CircularStdBold",
              fontSize: 16,
               color: '#000' }}>{configJSON.subTotal}</Text>
          <Text
           style={{ 
            fontFamily: "CircularStdBold", 
            fontSize: 16, 
            color: '#000' }}>
              £{this.state?.customForm?.attributes?.cost_summery?.sub_total}
              </Text>
        </View>
      </View>
    );
  }
  renderTableCell( data :any) {
    const { is_service, is_bespoke, base_service_detail } = data.attributes;
    const packageAvailable = base_service_detail?.data?.attributes?.package_available;
    const tableCellStyle = webStyle.tablebodyCell;

    const is_serviceCheck = () =>{
      return (
        <span className="tooltiptext" style={{textAlign :'left',padding :'5px'}} >
            {
          is_service ? "This package has been Deactivated by London Filmed. Please get in touch with your Account Manager for more information." :
             "This service/package has been deactivated for your organisation."
        }</span>
      )   
    }
    const renderReusePackageCell = (isClickable:boolean, is_service:boolean) => (
      <TableCell
        align="left"
        className={isClickable ? "customTableCell" : ""}
        style={isClickable ? tableCellStyle : { ...tableCellStyle, color: 'gray' }}
        onClick={isClickable ? ()=> this.navigatePreviousCustomForm(data.attributes.id) : undefined}
      >
        {isClickable ? (
          "Reuse Package"
        ) : (
          <div className="tooltip">
            Reuse Package
            {is_serviceCheck()}
          </div>
        )}
      </TableCell>
    );

    if (is_service) {
      if (is_bespoke) {
        return renderReusePackageCell(packageAvailable, true);
      } else {
        return renderReusePackageCell(true, true);
      }
    } else {
      return renderReusePackageCell(false, false);
    }
  }

      handleMouseEnter = (index:any) => {
        this.setState({ hoverIndex: index });
      };
    
      handleMouseLeave = () => {
        this.setState({ hoverIndex: null });
      };
      renderReusePackageButton() {        
        const is_service = this.state.customForm?.attributes?.is_service;
        const is_bespoke = this.state.customForm?.attributes?.is_bespoke;
        const packageAvailable = this.state.customForm?.attributes?.base_service_detail?.data?.attributes?.package_available
        let isDisable = true;

        if(is_service){
          isDisable = is_bespoke ? !packageAvailable : false;
        }
        
        const buttonStyle = {
            backgroundColor: isDisable ? '#ccc' : '',
        };
    
        return (
          <Tooltip title={!is_service ? "This service/package has been deactivated for your organisation." : ""} placement="top">
            <div className="buttonWrapper btn-green w-195">
                <div className="buttonOutline" style={{ backgroundColor: isDisable ? '#ccc' : '#00BFBB' }} ></div>
                <Button
                    className="btn-sm w-195"
                    style={buttonStyle}
                    disabled={isDisable}
                    onClick={() => this.navigatePreviousCustomForm(this.state.customForm.id)}
                >
                    Reuse Package
                </Button>
            </div>
          </Tooltip>
        );
    }
    sortServices=(data:any)=>{
      const sortedData: any = [...data].sort((a, b) => {
        if (a?.attributes?.name === 'AV Packages') {
          return -1;
        } else if (
           'AV Packages' ===    
           b?.attributes?.name)
           {return 1; } 
           else if (a?.attributes?.name === 'Virtual Packages') {
          return -1; 
        } else if (
          b?.attributes?.name === 
          'Virtual Packages') 
          {return 1;} else if ('Live Streaming Packages' ===    a?.attributes?.name)  {
          return -1; 
        } else if (
          b?.attributes?.name === 
          'Live Streaming Packages') 
          {return 1; } else if (a?.attributes?.name === 'Event Filming Packages') {
          return -1; 
        } else if (
            
          'Event Filming Packages' === b?.attributes?.name) 
          {return 1;} 
          else if ('Event Photography Packages'=== a?.attributes?.name) {
          return -1; 
        } else if (
          b?.attributes?.name ===
           'Event Photography Packages') 
           {return 1;} 
           else if ('Bespoke Request'===     a?.attributes?.name) {
          return 1; 
        } else if (
                 b?.attributes?.name     === 
          'Bespoke Request')
           { return -1;} else {
          return 0; 
        }
      });
      return sortedData || data
    }
    
  // Customizable Area End
  render() {
     // Customizable Area Start
     const { previousCategoryList, currentPage, recordsPerPage ,isLoading ,isLoadingPrevious,isViewLoading} = this.state
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const { data: inquiriesData = [] } = previousCategoryList?.inquiry || {};
    const currentRecords = inquiriesData.slice(indexOfFirstRecord, indexOfLastRecord);

     if (isLoading) {
          return  <div style={{ display: 'flex', justifyContent: 'center', 
          alignItems: 'center', height: '100%',
          width:"100%", backgroundColor: 'transparent' }}>
            <img 
            src={loadingImg}  
            style={{
              height:"50%", width:"25%"}}/>
      </div>
               }
        const reviewEventStartTime = (name: any, value: any): unknown => {
        if(name == "Event Start Time" || name == "Event End Time"){
          return this.getTimeString(value);
          }
          if(name == "Event Date"){
          return moment(value).format("DD/MM/YYYY")
          }
          return value; 
      }
      
      // Customizable Area End
    return (
      // Customizable Area Start
      <Container disableGutters maxWidth={false}> 
        <AppHeader
          logoUrl={imgLogo}
          homeText="Home"
          createImageUrl={createImage}
          helpCenterText="Help Centre"
          userUrl={createImage}
          settingsIcon
          helpCenterNotificationUrl={imgNotification}
          onPressHome={() => this.goToHome()}
          onClickViewProfile={() => this.goToProfile()}
          onClickHelpCenter={() => this.goToHelpCenter()}
          onClickSettings={() => this.goToSettingsFn()}
          onClickViewEnquiries={()=> this.goToEnquiryFn()}
          onClickQuote={() => this.goToQuoteFn()}
          data-test-id="testAppHeader"
          quoteUrl={group_plus}
        />
        <View
          style={{
            paddingTop: 40,
            paddingBottom: 40,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Container disableGutters maxWidth={false} style={{paddingLeft:22,paddingRight:60,marginBottom:35}}>
            <Text style={{ color:'#5A5A5A', marginBottom: 40,fontFamily: "CircularStd, sans-serif",}} onPress={this.goToHome} data-testid="gotoHome">
              Home
            </Text>
            <img src={imgRightArrow} width={10} style={{marginLeft:10,marginRight:10}} alt="imagRightArrow" />
            <Text style={{ color:'#594FF5',fontSize:16, marginBottom: 40,fontFamily: "CircularStdBold, sans-serif"}}>
              {configJSON.sType}
            </Text>
          </Container>
         <View style={{display:'flex',flexDirection:"row"}}>
            <View style={{ width:'262px',height:'100%',padding:10}}>
                <Text style={{
        fontSize: 22,
        lineHeight: 29,
        fontFamily: 'CircularStdBold',
        color: '#000',
        marginBottom: 20
      }}>{configJSON.createAccount}</Text>
                <div className="stepper">
                  <div className="d-flex flex-column">
                      <View  style={{display:'flex',flexDirection:"row"}}>
                        <Text style={{width:27,height:27,backgroundColor:'#DEDCFD',borderColor:'#000',borderWidth:2,
                        fontSize:20,fontFamily:'CircularStdBold',fontWeight:'700',display:"flex",alignItems:"center",justifyContent:"center"}}
                      >
                          1
                        </Text> 
                        <View style={{marginLeft:10,width:190,paddingTop:6}}> 
                        <Text
      style={{
        color: '#594FF5',
        fontFamily: 'CircularStdBold',
        fontSize: 16,
        marginBottom: 10
      }}
    >
      {configJSON.chooseServiceType}
    </Text> 
    <Text
      style={{
        color: '#5A5A5A',
        fontFamily: 'CircularStd',
        fontSize: 14,
        lineHeight: 19,
        paddingRight: 15
      }}
    >
      {configJSON.supportText}
    </Text>
                        </View>
                      </View>
                  </div>
                </div>
                <div className="d-flex flex-column" style={{marginBottom:20}}>
                  <View  style={{display:'flex',flexDirection:"row"}}>
                    <Text style={{width:27,height:27,backgroundColor:'#fff',borderColor:'#5A5A5A',borderWidth:2,color:'#5A5A5A',
                    fontSize:20,fontFamily:'CircularStdBold',fontWeight:'700',display:"flex",alignItems:"center",justifyContent:"center"}}
                  >
                      2
                    </Text> 
                    <View style={{marginLeft:10,width:190,paddingTop:6}}>
                    <Text
      style={{
        color: '#5A5A5A',
        fontFamily: 'CircularStd',
        fontSize: 16,
        marginBottom: 10
      }}
    >
      {configJSON.enterEventDetails}
    </Text>
                    </View>
                  </View>
                </div>
                <div className="d-flex flex-column">
                  <View  style={{display:'flex',flexDirection:"row"}}>
                    <Text style={{width:27,height:27,backgroundColor:'#fff',borderColor:'#5A5A5A',borderWidth:2,color:'#5A5A5A',
                    fontSize:20,fontFamily:'CircularStdBold',fontWeight:'700',display:"flex",alignItems:"center",justifyContent:"center"}}
                  >
                      3
                    </Text> 
                    <View style={{marginLeft:10,width:190,paddingTop:6}}>
                    <Text
      style={{
        color: '#5A5A5A',
        fontFamily: 'CircularStd',
        fontSize: 16,
        marginBottom: 10
      }}
    >
      {configJSON.reviewSubmit}
    </Text>
                    </View>
                  </View>
                </div>
              </View>
              <View style={{ flex:1,height:'100%'}}>
                <View style={{display:"flex",flexDirection:"row",marginBottom:25,alignItems:"center"}}>
                  <div data-testid="allPackage" className={`buttonWrapper w-195 btn-blue ${this.state.category === 'all' ? 'active' : ''}`} style={{marginRight:25}} onClick={() => this.setCategoryTxt("all")}>
                    <div className="buttonOutline"></div>
                    <Button
                      className="btn-sm" onClick={this.onAllPackagesClick}
                    >{configJSON.allPackage}</Button>
                  </div>
                  <div data-testid="bespoke"  className={`buttonWrapper w-195 btn-yellow ${this.state.category === 'bespoke' ? 'active' : ''}`} style={{marginRight:25}} onClick={() => this.setCategoryTxt("bespoke")}>
                    <div className="buttonOutline" style={{backgroundColor:"#FFC500"}}></div>
                    <Button
                      className="btn-sm" onClick={this.onBespokePackagesClick}
                    >{
                    configJSON.bespokePackage}</Button>
                  </div>
                  <div data-testid="previous"  className={`buttonWrapper w-220 btn-green ${this.state.category === 'previous' ? 'active' : ''}`} style={{marginRight:25}} onClick={() => this.setCategoryTxt("previous")}>
                    <div className="buttonOutline" style={{backgroundColor:"#00BFBB"}}></div>
                    <Button
                      className="btn-sm" onClick={this.onPreviousPackagesClick}
                    > {configJSON.previousePackage}</Button>
                  </div>
                </View>
              <View>   
              {this.state.category === 'all' || this.state.category=== 'bespoke' ?
                <Grid container spacing={4} data-test-id={"categoryListID"}>
                  {this.sortServices(this.state.categoryList)?.map((data:any) => {
                    return  (
                      <Grid item md={4} sm={6} xs={12} key={data.id} >
                        <View style={[styles.cardLayout, {overflow:"hidden" }]}>
                          <Image
                            source={{uri:data.attributes.image}}
                            resizeMode="cover"
                            style={styles.imgEnquire}
                          />
                          <View
                            style={{
                              paddingRight: 20,
                              paddingBottom: 25,
                              paddingTop:10,
                              paddingLeft: 20,
                              borderTopColor:"#000000",
                              borderTopWidth:3,
                              backgroundColor:"#fff"
                            }}
                          >
                            <View
                              style={[
                                styles.flexColumn,
                                { justifyContent: "space-between" ,backgroundColor:"#fff"},
                              ]}
                            >
                              <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
  <View style={{ height: 135, backgroundColor: "#fff", flex: 1 }}>
    <Text style={{ fontFamily: "CircularStdBold", fontSize: 18, color: "#000", lineHeight: 28, marginBottom: 5 }}>{data.attributes.name}</Text>
    <Text style={{ fontSize: 13, fontFamily: "CircularStd", color: "#594FF5", marginBottom: 3 }}>Starts from £{Number(data.attributes.start_from_price).toLocaleString()}</Text>
    <Text style={{ overflow: 'hidden', fontSize: 14, color: "#5A5A5A", lineHeight: 18, fontFamily: "CircularStd", marginBottom: 20 }} numberOfLines={3} ellipsizeMode={'tail'}>
      {data.attributes.description}
    </Text>
  </View>
  {this.renderCompanyLogo(data)}
</View>

                              <div className="buttonWrapper btn-blue" style={{marginTop:"auto"}}>
                                <div className="buttonOutline"></div>
                                <Button
                                  className="btn-sm" 
                                  data-testid={`select-package-${data.id}`} 
                                  onClick={() => this.setSubcategory(data)}
                                > {configJSON.selectPackage}</Button>
                              </div>
                            </View>
                            
                          </View>
                        </View>
                      </Grid>
                    )}
                  )}
                </Grid>
              : 
                <View style={{justifyContent:"center",display:"flex"}}>
                  <View>
                    <div>
                        <Typography variant="h1" style={{fontSize:'30px',color:'#000',fontFamily: "CircularStd, sans-serif", fontWeight:700, textTransform:'capitalize',paddingTop:20}}>Previous Packages ({inquiriesData.length})</Typography>
                        <div style={{background:"#ffffcc",border:"3px solid black",marginBottom:"5px",marginTop:"20px",marginRight:20}}>
                        <TableContainer component={Paper} style={{ boxSizing: "border-box", paddingBottom: "60px", paddingLeft: "30px", paddingRight: "10px", paddingTop: "20px", minHeight: 600 }}>
  {isLoadingPrevious ? (
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60%',width:"100%", backgroundColor: 'transparent' }}>
    <img src={loadingImg} style={{ height: "30%", width: "10%" }} alt="Loading..." />
    </div>
  ) : (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={webStyle.tableCell}>User Name</TableCell>
          <TableCell style={{...webStyle.tableCell,cursor:"pointer"}} data-test-id="packageHeaderId" onClick={()=>this.handleSortClick('package')}
          >Package {this.renderSortIcon('package')}</TableCell>
          <TableCell style={{...webStyle.tableCell,cursor:"pointer",minWidth:150}} data-test-id="eventNameId" onClick={()=>this.handleSortClick('eventName')}
          >Event Name {this.renderSortIcon('eventName')}</TableCell>
          <TableCell style={{...webStyle.tableCell,cursor:"pointer",minWidth:150}} data-test-id="eventDateId" onClick={()=>this.handleSortClick('eventDate')}
          >Event Date {this.renderSortIcon('eventDate')}</TableCell>
          <TableCell style={webStyle.tableCell}>Amount</TableCell>
          <TableCell align="left" style={webStyle.tableCell}>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {currentRecords?.map((d: any) => (
          <TableRow key={d.id}>
            <TableCell className="customTableCell" style={webStyle.tablebodyCell} onClick={()=>this.viewInquiry(d.id)}>{d.attributes.client_name}</TableCell>
            <TableCell className="customTableCell" style={{...webStyle.tablebodyCell,maxWidth:170}}onClick={()=>this.viewInquiry(d.id)}>{d.attributes.service_name}</TableCell>
            <TableCell className="customTableCell" style={{...webStyle.tablebodyCell,maxWidth:140}}onClick={()=>this.viewInquiry(d.id)}>{d.attributes.event_name}</TableCell>
            <TableCell className="customTableCell" style={webStyle.tablebodyCell} onClick={()=>this.viewInquiry(d.id)}>
              {d.attributes.event_date ? (
                <>
                  {new Intl.DateTimeFormat('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  }).format(new Date(d.attributes.event_date))}
                </>
              ) : (
                " "
              )}
            </TableCell>
            <TableCell className="customTableCell" style={webStyle.tablebodyCell} onClick={()=>this.viewInquiry(d.id)}>
              £{d.attributes.cost_summery.sub_total}
              </TableCell>
            {this.renderTableCell(d)}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )}
</TableContainer>


                        </div>
                    </div>
                    
                    
          <div style={{ backgroundColor: "#FFFFFF", height: "64px", boxSizing: "border-box", padding: "15px", display: "flex", justifyContent: "space-between" }}>
    <div style={{ display: "flex", alignItems: "center", gap: "14rem", marginLeft: "auto", marginRight: "auto" }}>                    <Pagination 
    data-test-id="testPage"
    count={Math.ceil(inquiriesData.length / recordsPerPage)} 
    page={this.state.currentPage} 
    defaultPage={3} siblingCount={0} boundaryCount={1}
    onChange={(event, value) => { this.handlePageChange(value) }}
    style={{ 
        display: 'flex', 
        justifyContent: 'center',
        fontFamily: 'CircularStd' 
    }} 
    hidePrevButton={this.state.currentPage === 1} 
    hideNextButton={this.state.currentPage === Math.ceil(inquiriesData.length / recordsPerPage)} 
    renderItem={(item) => (
        <PaginationItem
            {...item}
            component="div"
            style={{
                textDecoration: item.page !== this.state.currentPage ? 'underline' : 'none',
                color: item.page === this.state.currentPage ? '#594FF5' : 'inherit',
                backgroundColor: item.page === this.state.currentPage ? 'transparent' : 'inherit',
               
            }}
        />
    )}
/>
</div>
    <Box style={{
        fontSize: '12px',
        fontWeight: 'normal',
        color: '#5A5A5A',
        margin: '0',
        lineHeight: '1',
    }}>
                           
                           <p>{`${inquiriesData.length > 0 ? indexOfFirstRecord + 1 : 0
                                            } - ${inquiriesData.length > 0
                                                ? Math.min(indexOfLastRecord, inquiriesData.length)
                                                : 0
                                            } of ${inquiriesData.length} results`}</p>
                            </Box>
                            </div>
                    </View>
                </View>
              }
                 {this.state.category === 'all' || this.state.category=== 'bespoke'&& this.state.yourPackageMessageBoolean && <p style={{fontFamily:"CircularStd", fontSize:20,paddingLeft:25,paddingTop:25}}>{this.state.yourPackageMessage}...</p>} 
              </View>
            </View>
          </View>
         
        </View>
        <Dialog id="model_add" maxWidth="lg" open={this.state.showModel} onClose={this.hideModal} className="modal-category" style={{border:'3px solid black',overflow:'hidden',}}>
          <DialogContent style={{height:"655",overflowX:"hidden",border:'3px solid black',margin:-10,}}> 
            <View style={{borderBottomColor:"#CBD5E1",borderBottomWidth:1,borderStyle:"solid",paddingBottom:10,marginBottom:10}}>
              {Object.keys(this.state.subcategory).length > 0 &&
                <GenericLabel fontFamily="CircularStdBold" fontSize={28} color="#000">{this.state.subcategory.name}</GenericLabel>
              }
              <GenericLabel fontFamily="CircularStd" fontSize={14} lineHeight={30} color="#5a5a5a">Please choose the duration you require.</GenericLabel>
            </View>
            <div data-testid="hideModal1" onClick={() => this.hideModal()} style={{position:"absolute",right:17,top:17,cursor:"pointer"}}>
              <Image 
                source={imgClose}
                resizeMode="cover"
                style={{height:18,width:18,}}
              />
            </div>
            <View style={{display:"flex"}}>
            {Object.keys(this.state.subcategory).length > 0 &&
             <Slider 
             infinite={false}
             speed={500}
             slidesToScroll={1}
             slidesToShow={Math.min(3.07, this.state.subcategory.sub_categories.data.length)}
             arrows={this.state.subcategory.sub_categories.data.length > 1 ? true : false}
             variableWidth={false}
             prevArrow = {<div className="rightArrow">
               <img src={carouselArrow} className="arrowCircle" alt="LeftArrow"  /></div>}
             nextArrow= {<div ><img src={carouselArrow}  alt="RightArrow"/></div>}
           >
                {this.sortArr(this.state.subcategory.sub_categories.data)?.map((data:any,i:number) => {
                 const colorTheme= data.attributes.color_theme;
                 return(
                  <div style={{width:386}} key={i}>
                    <div style={{width:"80%",backgroundColor:"#DEDCFD",borderColor:"#000",borderWidth:3,height:'100%',padding:20,paddingTop:20,paddingBottom:20 ,borderStyle:"solid",marginRight:20,}}>
                      <View style={{display:"flex",flexDirection:"row",alignItems:"center",marginBottom:10}}>
                        <div className={`subcategory${i+1}`} style={{display:"flex",alignItems:"center",justifyContent:"center",height:60,width:60,borderStyle:"solid",borderWidth:3,borderColor:"#000",backgroundColor: colorTheme}}>
                          <Image
                              source={{uri:data.attributes.image}}
                              resizeMode="cover"
                              style={{height:40,width:40,}}
                            />
                        </div>
                        <View style={{paddingLeft:20}}>
                        <Text style={{ fontFamily: "CircularStd", fontSize: 14, color: "#000", lineHeight: 20 }}>
      {data.attributes.duration < 1 ? 'Multi-Day' : `Less than ${data.attributes.duration} hours`}
    </Text>

<Text style={{fontFamily: "CircularStdBold", fontSize: 20, color: "#000"}}>
    {data.attributes.name}
</Text>
                        </View>
                      </View>
                      <View>
                      <Text style={{fontFamily: "CircularStd", fontSize: 14, color: "#000", lineHeight: 20}}>
    Package Start From
</Text>

                      </View>
                      <View>
                      <Text style={{fontFamily: "CircularStdBold", fontSize: 40, color: "#000", marginTop: 3, marginBottom: 6}}>
                      £{Number(data.attributes.start_from).toLocaleString()}
</Text>

                      </View>
                      <View>
                      <Text style={{fontFamily: "CircularStdBold", fontSize: 15, color: "#000"}}>
    All Included Features
</Text>

                      </View>
                      <ol className="feature-list" style={{listStyle:"none",margin:0,padding:0,marginTop:15,marginBottom:10,height:240,overflowY:"scroll"}}>
                        {data.attributes.features.data.map((listData:any) => 
                          <li style={{display:"flex",alignItems:"center",marginBottom:10}} key={listData.id}>
                            <Image
                              source={checkBox}
                              resizeMode="cover"
                              style={{height:15,width:15,marginRight:10}}
                            />
                            <Text style={{fontFamily: "CircularStd", fontSize: 15, color: "#000", lineHeight: 26}}>
    {listData.attributes.name}
</Text>

                          </li>
                        )}
                      </ol>
                      <div data-testid="selectPackage"  className={`buttonWrapper btn-subcategory${i+1}` } style={{marginBottom:10,marginLeft:15,width:220}}>
                        <div className={`buttonOutline subcategoryBg${i+1}`}></div>
                        <Button
                          className="btn-sm"
                          data-testid ={`selectSubcategory${data.id}`}
                          onClick={() => this.navigateCustomForm(data)}
                        > {configJSON.selectPackage}</Button>
                      </div>
                    </div>
                  </div>
                 );
                })}
              </Slider>
              }
            </View>

            <div data-testid="hideModalText" onClick={() => this.hideModal()} style={{paddingTop:10,paddingBottom:5,cursor:"pointer",textAlign:"center"}}>
              <Text style={{textDecorationStyle:"solid",textDecorationLine:"underline", color:"#000" ,fontFamily:'CircularStd',fontSize:16 }}>Back</Text> 
            </div>
          </DialogContent>
        
        </Dialog>
        <Dialog data-testid="dialog"
            open={this.state.openDialogName === "ViewEnquiryStatus" }
            className="custom-modal" transitionDuration={0}
            aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description" scroll="body" >
            <DialogContent style={{ padding: "0px",border:"solid 3px #000",
            overflowX:"hidden",minHeight:"10%"}} data-test-id="form">
                {isViewLoading ? (
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60%',width:"100%", backgroundColor: 'transparent' }}>
    <img src={loadingImg} style={{ height: "30%", width: "10%" }} alt="Loading..." />
    </div>
  ) : (
              <View style={{ paddingBottom:"30px"}} >
                <Box padding={{ 
                  xs: "15px 30px 25px",}}>
                <div data-testid="hideModal" onClick={this.onCancel} 
                style={{position:"absolute",right:20,top:10,cursor:"pointer",}}>
              <Image
                data-testid="closeBtn"
                source={imgClose} resizeMode="cover" style={{height:18,width:18,}}
              />
            </div>
                  <Text style={{fontFamily:"CircularStdBold", 
                  color:"#000000",fontSize:30,paddingBottom:20,
                  lineHeight:62}}>
                    Event Details</Text>
                  <View style={{
              backgroundColor: getStatusColor(this.state?.customForm?.attributes?.status),
              borderColor: '#000', borderWidth: 3,
              padding: 5,marginBottom: 20,
              width:182,
            }}>
          {this.state?.customForm?.attributes&& 
    <Text style={{ paddingLeft: 40,fontFamily:"CircularStdBold" }}>
      {this.state.customForm.attributes?.status?.charAt(0).toUpperCase() + this.state.customForm.attributes?.status.slice(1)}
    </Text>
 }
           </View>
           <div style={{borderBottom:"1px solid grey"}}></div>
                </Box>
                <View style={{ 
                  paddingLeft:30,
                  paddingRight:30,
                  paddingTop:30
                  }}>
                  <Grid 
                  container 
                  spacing={4} >
                 
                 <Grid container spacing={3} className="inputFieldsAlignEnd" style={{ paddingLeft: 15, paddingRight: 30, maxHeight:250, overflow: "auto", marginBottom: "20px" }}>
    {this.state.customForm &&
        this.state.customForm?.attributes?.base_service_detail?.data?.attributes?.input_values?.data?.map(
          (input: any, index: any) => (
            input.attributes.user_input && 
            (
                <Grid item xs={3} 
                key={index}>
                    <div style={
                      { marginBottom: "5px", fontSize: "13px", 
                      fontFamily: "CircularStdBold" }}>
                        {input.attributes.input_field.name}
                    </div>
                    <TextField
                        className="customInput"
                        inputProps={{ style: 
                          { backgroundColor: "#fff", color: "#000" } }}
                          value=
                       {
                        reviewEventStartTime(input.attributes.input_field.name,input.attributes.user_input)
                      }
                        variant="outlined" 
                        fullWidth disabled />
                </Grid>
            )
        ))}
    {this.state.groupByAdditionalAddon && 
    this.state.groupByAdditionalAddon.map((service: any) => (
        service.attributes.input_values.data.map((input: any, index: any) => (
            input.attributes.user_input && (
                <Grid item xs={3} 
                key={index}>
                    <div style={
                      { marginBottom: "5px", fontSize: "13px", fontFamily: "CircularStdBold" }}>
                        {input.attributes.input_field.name}
                    </div>
                    <TextField
                        className="customInput"
                        inputProps={{ style: 
                          { backgroundColor: "#fff", color: "#000" } }}
                        value={
                          input.attributes.user_input}
                        variant="outlined" 
                        fullWidth disabled />
                </Grid>
            )
        ))
    ))}
</Grid>
                    <Grid item xs={7} style={{marginRight:"40px"}}>
                    {this.renderViewCustomFormPopupPriceDetails()}
                  </Grid>
                  <Grid item 
                  xs={4}>
                  <Grid item 
                  xs={4} >
  {this.state?.customForm?.attributes &&
   this.state?.customForm?.attributes?.files && (
    <>
    <View
     style={{ width:300}}>
      <Text style={{ fontFamily: "CircularStdBold", color: "#000000", fontSize: 20, lineHeight: 62 }}>Attachments</Text>
      <div style={{maxHeight:"126px", overflow:"auto"}}>
        {this.state.customForm.attributes.files.length > 0 ? (this.state.customForm.attributes.files.map((file: any) => (
            <View 
            key={file.id} 
            style={{ backgroundColor: '#F0F0F0', borderColor: '#000', borderWidth: 3,padding: 5,marginBottom: 10,}}>
              <Text>{file.name}</Text>
           </View>
          ))
        ) : (
            <Text>No Files Added</Text>
            )}
      </div>
    </View>
    </>
  )}
</Grid>

</Grid>

                  <View style={{ display:"flex",width:"100%",
                    paddingLeft:"1%",
                    paddingTop:30,paddingRight:20}}>
                  <Grid container spacing={3}>
      <Grid item sm={12} style={{ display: "flex", justifyContent: "end", flexDirection: "row" , 
    }}>
                        <div className={`buttonWrapper w-220 btn-subcategory3`} style={{ marginRight: 20 }}>
                            <div className="buttonOutline" style={{ backgroundColor: "#FF5B74" }}></div>
                            <Button className="btn-sm w-195" onClick={this.onCancel} >Back</Button>
                        </div>
                        {this.renderReusePackageButton()}
                        
      </Grid>
    </Grid>
                        </View>
                        
                  </Grid>
                  </View>
                </View>
  )}
            </DialogContent>
          </Dialog>
      </Container>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const styles = StyleSheet.create({
 
  cardLayout : {
    height:370,
    borderWidth:3,
    borderStyle:'solid',
    borderColor:'#000',
    display: 'flex',position:'relative',zIndex:1,
  },
  fontSm: {
    fontSize:12,fontFamily:"CircularStd",
  },
  imgEnquire: {
    height:157,
  },
  flexColumn :{
    display:'flex',
    flexDirection:"column",
  },
  cardSubTiitle: {
    fontSize:18,
    fontWeight:'600',
    paddingTop:20,
    paddingBottom:20,
    fontFamily:'CircularStdBold',
  },
  flexRow: {
    display:'flex',
    alignItems:'center',
    flexDirection:"row"
  },
  tableProvision : {
    display: "flex",justifyContent: "space-between",
    alignItems: "center",flexDirection:"row",
  },
});
const webStyle = {
  pageCount:{
    fontSize:12,
    color:'#5A5A5A',
    fontWeight:'normal',
    lineHeight:1,
    padding:'5px',
    cursor:'pointer',
    border: 'none',
    background: 'transparent',

}as const,
tableCell:{
    fontSize:'20px',
    color:'#000',
    fontFamily: "CircularStd, sans-serif", 
    fontWeight:700,
    borderBottom:'none',
    textTransform: 'capitalize',
}as const,
tablebodyCell:{
    fontSize:'16px',
    color:'#000',
    fontFamily: "CircularStd, sans-serif", 
    fontWeight:400,
    textTransform: 'capitalize',
    cursor: "pointer",
}as const,
FilterData:{
    position: 'absolute',
    top: '0px',
    right: '0px',
    border: '3px solid #000',
    background: '#fff',
    padding: '5px',
    boxSizing: 'border-box',

}as const,
btnFilters:{
    borderRadius: 0,
    padding: '0 5px',
    fontSize: 14,
    fontWeight: 'normal',
    color: '#5A5A5A',
    fontFamily: 'CircularStd',
    textTransform: 'capitalize',
   
}as const,
btnFilters1:{
    borderRadius: 0,
    padding: '0 5px',
    fontSize: 14,
    fontWeight: 'normal',
    color: '#5A5A5A',
    fontFamily: 'CircularStd',
    textTransform: 'capitalize',
    borderLeft: '1px solid #000',
    borderRight: '1px solid #000',
}as const,
    hoverStyle: {
      // Define your hover style here
      textDecoration: 'underline',
      color: '#594FF5',
    }as const
}
const getStatusColor = (status:any) => {
  switch (status) {
    case 'approved':
      return '#00BFBB';
    case 'rejected':
      return '#F87171'; 
    case 'draft':
      return '#FFC500'; 
    case 'pending':
      return '#B0B0B0'; 
    default:
      return '#FFFFFF'; 
  }
};


// Customizable Area End