// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const profile = require("../assets/profile.png");
export const uploadIcon = require("../assets/3cf3c86ceea817fd429f347f064a682a849290bb.png");
export const search = require("../assets/search.png");
export const checked = require("../assets/checked.png");
export const unchecked = require("../assets/unchecked.png");
export const plus = require("../assets/plus.png");
export const defaultProfile = require("../assets/default.png");
export const upload = require('../assets/upload.png')
export const alert = require("../assets/alert.png");
export const remove = require('../assets/remove.png')
export const success = require("../assets/success.png");
export const cross = require("../assets/imagebutton_Cancel.png")
export const ArrowDown = require("../assets/image_ArrowDown.png")
export const ArrowUp = require("../assets/image_ArrowUp.png")
export const countryCodeSearchIcon = require("../assets/search.png");
export const search_Bitmap = require("../assets/search_Bitmap.png");
export const squeezemeinBigPicture = require("../assets/right_side_image.png");
export const eyeOpen = require("../assets/eye_open.png");
export const eyeClose = require("../assets/eye_close.png");
export const errorSymbol = require("../assets/error_symbol.png");
export const userProfile = require("../assets/user.png")
export const rightArrow = require("../assets/aa441591d93c705362dc93c46626aab634a781dd.png")
export const accountImg = require("../assets/accountImg.png")
export const imgUser = require("../assets/user.png")
export const imgNotification = require("../assets/notification.png")
export const warning = require("../assets/warning.svg")
export const imgLogo = require("../assets/logo.png")
export const companyIcon = require("../assets/companyIcon.svg");
export const lastNameIcon =  require("../assets/lastNameIcon.svg");
export const removeIcon = require("../assets/removeIcon.svg");
export const editIcon = require("../assets/editIcon.svg");
export const group_plus = require("../assets/group_plus.svg");
// Customizable Area End
