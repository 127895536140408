import React from "react";

//Customizable Area Start
import {
  Container,
  Box,
  Typography,
  Grid,
  TextField,
  Button
} from "@material-ui/core";
import { View, StyleSheet, Text, Image } from "react-native";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import { styled } from "@material-ui/styles";
import {
  imgLogo,
  imgBanner,
} from "./assets";
import NewPasswordController, {
  Props,
  configJSON
} from "./NewPasswordController.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
//Customizable Area End

export default class NewPassword extends NewPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    this.isChangePassword = true;
    //Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container
          disableGutters
          maxWidth={false}
          style={{
            height: "100vh"
          }}
        >
          <View style={{backgroundColor:'#594FF5',height:76,borderBottomColor:'#000',borderBottomWidth:3,borderStyle:"solid"}}></View>
          <Grid
            container
            style={{
              height: "92%",
            }}
          >
            <Grid
              item
              sm={6}
              style={{
                position: "relative", borderRightColor:'#000',borderRightWidth:3,borderRightStyle:"solid",
                backgroundColor: "#F6F0FF",
                overflow: "hidden"
              }}
            >
                <View style={{height:'68%',justifyContent:'center',alignItems:'center',}}>
                  <img
                    alt="carousel-right-tumbnail"
                    style={{width:'80%'}}
                    src={imgBanner}
                  />
              </View>
              <View style={styles.staticBottomContent}>
                <img
                  style={{ height: "52px", marginBottom: "30px" }}
                  src={imgLogo}
                  alt="mainImg"
                />
                <Typography
                  style={{
                    textAlign: "center",
                    lineHeight: "26px",
                    fontFamily: 'CircularStd',
                    color: "#000",
                    fontSize: "16px",
                  }}
                >
                  {configJSON.supportText}
                </Typography>
              </View>
            </Grid>
            <Grid
              sm={6}
              item
              container
              alignItems="center"
              justifyContent="center"
              style={{
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box sx={{ width: "370px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px 0px"
                  }}
                >       
                 
                  {/* -------------------- BODY ----------------------- */}
                  <View style={{ width: "100%" }}>
                    <Typography
                      variant="h4"
                      component="h2"
                      style={{
                        fontSize: 30,
                        color: "#000000ff",
                        justifyContent: "center",
                        marginBottom: "30px",
                        display: "flex",
                        fontFamily:'CircularStdBold',
                        width:"100%",
                      }}
                    >
                      {configJSON.changePassword}
                    </Typography>
                    <Formik
                      initialValues={{ password: "", confirmPassword: "" }}
                      validationSchema={Yup.object().shape(
                        this.state.passwordSchema
                      )}
                      validateOnMount={true}
                      validateOnChange={true}
                      enableReinitialize
                      onSubmit={(values, actions) => {
                        this.goToConfirmationAfterPasswordChange(values);
                        actions.setSubmitting(false);
                      }}
                      data-testid="changePasswordForm"
                    >
                      {({
                        setFieldValue,
                        values,
                        handleSubmit,
                        touched,
                        errors
                      }) => (
                        <form onSubmit={handleSubmit} className="mt-5 mb-2">
                          <Grid container spacing={0}>
                            

                              {this.state.errorFailureMessage.length > 0 && (
                              <AlertBox data-testid="alerMessage">
                                {this.state.errorFailureMessage}
                              </AlertBox>
                              )}

                            {((touched.confirmPassword && errors.confirmPassword) || (touched.password && errors.password)) && (
                              <AlertBox data-testid="alerMessage">
                                {errors.password ? <div>{errors.password}</div> :  "" }
                                {errors.confirmPassword ? <div>{errors.confirmPassword}</div> :  "" }
                              </AlertBox>
                            )}



                            <Grid item xs={12}>
                              <Text style={styles.customLabel}>
                                New Password
                              </Text>
                              <CustomFormWrapper>
                                <CustomInputStyle
                                  autoFocus
                                  fullWidth
                                  name="password"
                                  placeholder="*********"
                                  type={
                                    this.state.enablePasswordField
                                      ? "password"
                                      : "text"
                                  }
                                  data-testid={"txtInputPassword"}
                                  value={values.password}
                                  inputProps={{
                                    style: {
                                      height: "23"
                                    }
                                  }}
                                  onChange={event => {
                                    setFieldValue(
                                      "password",
                                      event.target.value
                                    );
                                    this.resetErrorMessage();
                                  }}
                                />
                                <div
                                  className="eye-icon"
                                  onClick={() => {
                                    this.setState({
                                      enablePasswordField: !this.state
                                        .enablePasswordField
                                    });
                                  }}
                                  data-testid="passwordShowHideIcon"
                                >
                                  <Image
                                    source={
                                      this.state.enablePasswordField
                                        ? this.imgPasswordVisible
                                        : this.imgPasswordInVisible
                                    }
                                  />
                                </div>
                              </CustomFormWrapper>
                             
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 30 }}>
                              <Text style={styles.customLabel}>
                                {configJSON.reenterLabelPassword}
                              </Text>
                              <CustomFormWrapper>
                                <CustomInputStyle
                                  fullWidth
                                  autoFocus
                                  key={values.confirmPassword}
                                  name="confirmPassword"
                                  placeholder="*********"
                                  type={
                                    this.state.btnConfirmPasswordShowHide
                                      ? "password"
                                      : "text"
                                  }
                                  data-testid={"txtInputConfirmPassword"}
                                  value={values.confirmPassword}
                                  inputProps={{
                                    style: {
                                      height: "23"
                                    }
                                  }}
                                  onChange={event => {
                                    setFieldValue(
                                      "confirmPassword",
                                      event.target.value
                                    );
                                    this.resetErrorMessage();
                                  }}
                                />

                                <div
                                  className="eye-icon"
                                  onClick={() => {
                                    this.setState({
                                      btnConfirmPasswordShowHide: !this.state
                                        .btnConfirmPasswordShowHide
                                    });
                                  }}
                                  data-testid="confirmPasswordShowHide"
                                >
                                  <Image
                                    source={
                                      this.state.btnConfirmPasswordShowHide
                                        ? this.imgPasswordVisible
                                        : this.imgPasswordInVisible
                                    }
                                  />
                                </div>
                              </CustomFormWrapper>
                              
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: 30 }}>
                              <CustomFormWrapper>
                                <CustomButtonOutline />
                                <SubmitButton
                                  type="submit"
                                  data-testid="changePasswordButton"
                                >
                                  Change Password
                                </SubmitButton>
                              </CustomFormWrapper>
                            </Grid>
                          </Grid>
                        </form>
                      )}
                    </Formik>
                  </View>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomFormWrapper = styled("div")({
  position: "relative",
  height: 46,
  display: "block"
});

const CustomFormOutline = styled("div")({
  border: "solid 3px #000",
  height: 33,
  position: "absolute",
  right: "-7px",
  bottom: 0,
  width: "100%",
  backgroundColor: "#594FF5",
  zIndex: 0
});


const CustomInputStyle = styled(TextField)({
  "& .MuiInputBase-fullWidth": {
    margin: 0
  },
  "& .MuiInputBase-input": {
    backgroundColor: "#fff",
    height: "40px",
    position: "relative",
    zIndex: 1,
    borderColor: "#000",
    width: "100%",
    paddingLeft: "5px",
    paddingRight: "5px",
    borderStyle: "solid",
    borderWidth: 3,
  },
  "& inpur:before": {
    content: "none"
  }
});

const SubmitButton = styled(Button)({
  backgroundColor: "#fff",
  color: "#000",
  border: "solid 3px #000",
  borderRadius: 0,
  textTransform: "none",
  fontSize: "16px",
  width: "100%",
  padding: "4px",
  fontFamily:'CircularStdBold', 
  "&:hover": {
    backgroundColor: "#BDB9FB",
  },
  "&:focus": {
    backgroundColor: "#DEDCFD",
  }
});

const CustomButtonOutline = styled("div")({
  border: "solid 3px #000",
  height: 33,
  position: "absolute",
  right: "-5px",
  bottom: '-1px',
  width: "98%",
  backgroundColor: "#594FF5",
  zIndex: 0,
});

const AlertBox = styled("div")({
  padding:20,
  backgroundColor: "#FEE2E2",
  borderLeftColor:"#FF5B74",
  boxSizing:'border-box',
  borderTopLeftRadius:5,
  borderBottomLeftRadius:5,
  fontFamily: 'CircularStd',
  borderStyle:"solid",
  marginBottom:30,
  color:"#FF5B74",
  width:'100%',
  border:0,
  borderLeft:4,
});

const styles = StyleSheet.create({
  customLabel: {
    fontSize: 16,
    color: "#000",
    marginBottom: 5,
    fontFamily: 'CircularStdBold',
    display: "flex"
  },
  staticBottomContent: {
    maxWidth:'596px',
    marginLeft: "auto",
    marginRight:"auto",
    alignItems: "center",
    padding: "0 20px",
  }
});

// Customizable Area End
